import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Grid, InputAdornment, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import SellIcon from '@mui/icons-material/Sell';
import { useSelector } from 'react-redux';
import AddNewBrokerModal from '../../Purchase/Components/AddNewBrokerModal';
import AddNewPartyModal from '../../Purchase/Components/AddNewPartyModal';
import { RootState } from '../../../store/store';
import CustomSnackBar, { snackbarType } from '../../../Component/CustomSnackBar';


// Define the type for countryRates
interface CountryRates {
    [key: string]: number;
}

interface SellInvoiceProps {
    data: any;
}

const SellInvoice: React.FC<SellInvoiceProps> = ({ data }) => {
    const user = useSelector((state: RootState) => state.user.data);
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const [filterFormData, setFilterFormData] = useState({
        invoice_number: '',
        deal_date: getCurrentDate(),
        sell_party_name: '',
        sell_brokerage: '',
        sell_broker: '',
        currency_rate: '',
        converted_rate: '',
        sell_days: '',
        sold_price: '',
        discount_percentage: 0,
        sell_invoice_total: 0,
        sell_invoice_cmnt: '',
    });
    const countryOptions = [
        { value: 'INR', label: 'India', flag: 'https://flagcdn.com/w20/in.png' },
        { value: 'USD', label: 'United States', flag: 'https://flagcdn.com/w20/us.png' },
        { value: 'EUR', label: 'Euro', flag: 'https://flagcdn.com/w20/eu.png' },
        { value: 'JPY', label: 'Japan', flag: 'https://flagcdn.com/w20/jp.png' },
        { value: 'THB', label: 'Thailand', flag: 'https://flagcdn.com/w20/th.png' },
        { value: 'HKD', label: 'Hong Kong', flag: 'https://flagcdn.com/w20/hk.png' },
        { value: 'BEF', label: 'Belgium', flag: 'https://flagcdn.com/w20/be.png' },
        { value: 'AED', label: 'Dubai', flag: 'https://flagcdn.com/w20/ae.png' },

        { value: 'ILS', label: 'Israel', flag: 'https://flagcdn.com/w20/il.png' },
        { value: 'VND', label: 'Vietnam', flag: 'https://flagcdn.com/w20/vn.png' },
        { value: 'KHR', label: 'Cambodia', flag: 'https://flagcdn.com/w20/kh.png' },
        { value: 'CNY', label: 'China', flag: 'https://flagcdn.com/w20/cn.png' },
    ];

    const [openPartyModal, setOpenPartyModal] = useState(false);
    const [openBrokerModal, setOpenBrokerModal] = useState(false);
    const [countryRates, setCountryRates] = useState<CountryRates>({});
    const [selectedCountry, setSelectedCountry] = useState(countryOptions[0].value);
    const [convertedRate, setConvertedRate] = useState<number>();
    const [onSuccess, setOnSuccess] = useState<snackbarType>({ success: false, message: '' });

    const handleOpenPartyModal = () => setOpenPartyModal(true);
    const handleClosePartyModal = () => setOpenPartyModal(false);
    const handleOpenBrokerModal = () => setOpenBrokerModal(true);
    const handleCloseBrokerModal = () => setOpenBrokerModal(false);

    const prefix = user?.invoice_no?.replace(/\d+$/, '');
    const number = user?.invoice_no?.match(/\d+$/)?.[0] || '';
    const incrementedNumber = (parseInt(number, 10) + 1).toString();
    const NewInvoice = prefix + incrementedNumber.padStart(number.length, '0');

    useEffect(() => {
        fetchCountryRates();
    }, []);



    const handleInputChange = (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        setFilterFormData((prevFormData) => {
            let updatedData = { ...prevFormData, [fieldName]: value };

            if (fieldName === 'sold_price' && data.price && data.weight) {
                const invoiceTotal = parseFloat(value) / (data.price * data.weight);
                updatedData['discount_percentage'] = Number(((invoiceTotal - 1) * 100).toFixed(2)) || 0;
                updatedData['sell_invoice_total'] = parseFloat(value) + (parseFloat(value) * (Number(((invoiceTotal - 1)).toFixed(2)))) || 0;
            }

            return updatedData;
        });

    };

    const handleCountryChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        setSelectedCountry(selectedValue);

        const newConvertedRate = countryRates[selectedValue];
        setConvertedRate(newConvertedRate);

        setFilterFormData((filterFormData) => ({
            ...filterFormData,
            currency_rate: newConvertedRate?.toString() || '',
        }));
    };

    const fetchCountryRates = async () => {
        try {
            const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/USD`, {
                withCredentials: false
            });
            if (response) {
                setCountryRates(response.data.rates);
                setConvertedRate(response.data.rates['INR']);
                setFilterFormData((filterFormData) => ({
                    ...filterFormData,
                    currency_rate: response.data.rates['INR'].toString(),
                }));
            }
        } catch (error) {
            console.error('Currency rate not found');
        }
    };

    const handleSellStock = async () => {
        try {
            const response = await axios.post('/stock/sell-stock', {
                ...filterFormData,
                stock_id: data.stockId,
                certificate_id: data.certificateId
            });
            if (response.status == 200) {
                setOnSuccess({ message: `Item : ${data.stockId || data.certificateId} is sold`, success: true, variant: "success" });
            }

            // Handle success, such as showing a notification or clearing the form
        } catch (error: any) {
            setOnSuccess({ message: error.message, success: true, variant: "error" });
            console.error('Error selling stock:', error.message);
        }
    };


    return (
        <Box sx={{ width: '100%', margin: 'auto', padding: 2, backgroundColor: '#d9e3ef', borderRadius: 2, mt: 2.5 }}>
            <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />
            <Typography variant="h6" gutterBottom>Sell Stock</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        style={{ borderRadius: 4, backgroundColor: "white" }}
                        label="Invoice No."
                        fullWidth
                        defaultValue={NewInvoice}
                        onChange={handleInputChange('sell_invoice')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Deal Date"
                                fullWidth
                                value={filterFormData.deal_date}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                onChange={handleInputChange('sell_deal_date')}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Party Name"
                                fullWidth
                                value={filterFormData.sell_party_name}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <Button variant="contained" color="primary" onClick={handleOpenPartyModal}>Add New</Button>
                                //         </InputAdornment>
                                //     ),
                                // }}
                                onChange={handleInputChange('sell_party_name')}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Brokerage"
                                fullWidth
                                value={filterFormData.sell_brokerage}
                                onChange={handleInputChange('sell_brokerage')}
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Broker Name"
                                fullWidth
                                value={filterFormData.sell_broker}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <Button variant="contained" color="primary" onClick={handleOpenBrokerModal}>Add New</Button>
                                //         </InputAdornment>
                                //     ),
                                // }}
                                onChange={handleInputChange('sell_broker')}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth style={{ borderRadius: 4, backgroundColor: "white" }}>
                                <InputLabel>Currency</InputLabel>
                                <Select
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    label="Current Rate"
                                    renderValue={(value) => (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={countryOptions.find(option => option.value === value)?.flag}
                                                alt={countryOptions.find(option => option.value === value)?.label}
                                                style={{ marginRight: '8px', width: '24px', height: '16px' }}
                                            />
                                            {countryOptions.find(option => option.value === value)?.label}
                                        </Box>
                                    )}
                                >
                                    {countryOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={option.flag}
                                                    alt={option.label}
                                                    style={{ marginRight: '8px', width: '24px', height: '16px' }}
                                                />
                                                {option.label}
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Converted Rate"
                                fullWidth
                                value={convertedRate || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Days"
                                fullWidth
                                value={filterFormData.sell_days}
                                onChange={handleInputChange('sell_days')}
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Sold Price"
                                fullWidth
                                value={filterFormData.sold_price}
                                onChange={handleInputChange('sold_price')}
                                type='number'
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        style={{ borderRadius: 4, backgroundColor: "white" }}
                        label="Sell back"
                        fullWidth
                        value={filterFormData.discount_percentage}
                        onChange={handleInputChange('discount_percentage')}
                        type='number'
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        style={{ borderRadius: 4, backgroundColor: "white" }}
                        label="Invoice Total"
                        fullWidth
                        value={filterFormData.sell_invoice_total}
                        onChange={handleInputChange('sell_invoice_total')}
                        type='number'
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        style={{ borderRadius: 4, backgroundColor: "white" }}
                        label="Comments"
                        fullWidth
                        multiline
                        rows={2}
                        value={filterFormData.sell_invoice_cmnt}
                        onChange={handleInputChange('sell_invoice_cmnt')}
                    /></Grid>

                <Grid item xs={12} style={{ textAlign: "center" }}>

                    <Button variant="contained" color="primary" onClick={handleSellStock}>
                        <SellIcon sx={{ marginRight: 1 }} />
                        Sell Stock
                    </Button>
                </Grid>
            </Grid>
            <AddNewPartyModal open={openPartyModal} onClose={handleClosePartyModal} />
            <AddNewBrokerModal open={openBrokerModal} onClose={handleCloseBrokerModal} />
        </Box>
    );
};

export default SellInvoice;


