import React from "react";
import { Outlet } from "react-router";
import { Box, Container } from "@mui/material";
import Footer from "../Component/Footer";
import SideNav from "../Container/SideNav";
import { CheckPermission } from "../Component/CheckPermission";
import JewelryCarousel from "../Modules/Dashboard/Components/JewelryCarousel";
import { useLocation } from 'react-router-dom';
const Root: React.FC = () => {
    const location = useLocation();
    return (
        <div>
            <CheckPermission>


                {location.pathname === '/home' && <JewelryCarousel />}
                <SideNav>
                    <Box sx={{ maxWidth: '90%', mx: 'auto' }}>
                        {/* <Container> */}

                        <Outlet />
                        {/* </Container> */}
                    </Box>
                </SideNav>

                <Footer />
            </CheckPermission>

        </div >
    );
};

export default Root;
