import React, { useContext, useState } from "react";
import FilterForm from "../../../Component/FilterForm";

import { Box, Button, Grid } from "@mui/material";


import PurchaseInvoice from "../Components/PurchaseInvoice";
import LabInformation from "../Components/LabInformation";
import PurchaseDetails from "../Components/PurchaseDetails";
import { PurchaseContext, PurchaseContextProvider } from "../Context/purchaseProviderContext";
import axios from "axios";
import { setUserData } from "../../../store/slice/userSlice";
import { snackbarType } from "../../../Component/CustomSnackBar";



const Purchase: React.FC = () => {



    return (
        <PurchaseContextProvider>


            <Box sx={{ flexGrow: 1, display: "flex" }}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <FilterForm />
                    </Grid>
                    <Grid item xs={12}>
                        <LabInformation />
                    </Grid>
                    <Grid
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '16px 0px 0px 16px',
                            mb: 2,

                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 2
                        }}
                    >
                        <PurchaseInvoice />
                        <PurchaseDetails />

                    </Grid>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2, mb: 4 }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button> */}


                </Grid>
            </Box>
        </PurchaseContextProvider>

    );
};

export default Purchase;
function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}

