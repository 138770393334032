import React from "react";

import UnderConstruction from "../../Component/UnderConstruction";
const Payment: React.FC = () => {
    return (
        <UnderConstruction />
    );
};

export default Payment;
