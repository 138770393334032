import React, { useState, useRef, ChangeEvent, KeyboardEvent } from 'react';
import {
    Box,
    Typography,
    Paper,
    ThemeProvider,
    createTheme,
    CssBaseline,
    TextField,
    Button,
    Alert,
    Collapse
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate, useLocation } from 'react-router';
import bcrypt from "bcryptjs";
import CustomSnackBar, { snackbarType } from '../../../Component/CustomSnackBar';


const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        background: {
            default: 'transparent',
        },
    },
});

const EmailVerification: React.FC = () => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { email, data, correctotp } = location.state;
    const [alertOpen, setAlertOpen] = React.useState(false);

    const [onSuccess, setOnSuccess] = useState<snackbarType>({ success: false, message: '' });


    const handleOtpChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/\D/g, '');
        if (value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (value.length === 1 && index < 5) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (index: number) => (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handleVerify = async () => {
        const otpString = otp.join('');

        try {

            const isMatch = await bcrypt.compare(otpString, correctotp);

            if (isMatch) {
                navigate('/password', { state: { data } });
            } else {
                setAlertOpen(true);
            }
        } catch (error) {

            setOnSuccess({ message: `Error verifying OTP:${error}`, success: true, variant: "error" })

        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />

            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    backgroundImage: 'url("/login2.avif")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <Paper
                    elevation={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 4,
                        width: '100%',
                        maxWidth: 400,
                        backgroundColor: 'rgba(255, 255, 255, 0.75)',
                        backdropFilter: 'blur(10px)',
                        borderRadius: '16px',
                    }}
                >
                    <EmailIcon
                        sx={{ fontSize: 64, color: '#1976d2', marginBottom: '16px' }}
                    />
                    <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">
                        Email Verification
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        We have sent a 6-digit OTP to your registered email:
                        <Box component="span" fontWeight="bold" sx={{ display: 'block', mt: 1 }}>
                            {email}
                        </Box>
                        Please enter the OTP below to verify your account.
                    </Typography>
                    <Collapse in={alertOpen}>
                        <Alert severity="error" sx={{ mb: 2 }}>
                            Incorrect OTP, please try again
                        </Alert>
                    </Collapse>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            my: 3,
                        }}
                    >
                        {otp.map((digit, index) => (
                            <TextField
                                key={index}
                                inputRef={(el) => (inputRefs.current[index] = el)}
                                value={digit}
                                onChange={handleOtpChange(index)}
                                onKeyDown={handleKeyDown(index)}
                                variant="outlined"
                                inputProps={{
                                    maxLength: 1,
                                    style: {
                                        textAlign: 'center',
                                        fontSize: '1.4rem',
                                        padding: '12px 0',
                                    },
                                }}
                                sx={{
                                    width: '14%',
                                    '& .MuiInputBase-root': {
                                        height: '50px',
                                        backgroundColor: 'white',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#1976d2',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#1976d2',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#1976d2',
                                        },
                                    },
                                    '@media (max-width:400px)': {
                                        width: '13%',
                                        '& .MuiInputBase-root': {
                                            height: '40px',
                                        },
                                        '& .MuiInputBase-input': {
                                            fontSize: '1.2rem',
                                            padding: '8px 0',
                                        },
                                    },
                                }}
                            />
                        ))}
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleVerify}
                        disabled={otp.some(digit => digit === '')}
                        sx={{
                            mt: 2,
                            py: 1.5,
                            fontSize: '1.1rem',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                backgroundColor: '#1565c0',
                            },
                        }}
                    >
                        Verify OTP
                    </Button>
                </Paper>
            </Box>
        </ThemeProvider>
    );
};

export default EmailVerification;