import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/system";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Divider from "@mui/material/Divider";
import { Alert, Collapse } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Component/Logo";
import LoadingButton from "@mui/lab/LoadingButton";
// import Lottie from "lottie-react";
// import diamondloader from "../../../public/diamondloader.json";
// import { API_BASE_URL } from "../../../backend";

const BackgroundBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // padding: theme.spacing(3),
  backgroundImage: "url(/login2.avif)", // Replace with your actual image URL

  backgroundPosition: "center",
}));

const GlassContainer = styled(Container)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.75)",
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  padding: theme.spacing(4),
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="">
        Superb Diamonds
      </Link>{" "}
      {" | "}
      <Link color="inherit" href="">
        Twinkle Diam
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

const validatePhoneNumber = (phone) => {
  const re = /^[0-9]{10}$/;
  return re.test(String(phone));
};

export default function SignUp() {
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [country, setCountry] = React.useState("India");
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState("");

  const [formValues, setFormValues] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    phone_number: "",
    company_address: "",
    occupation: [],
    partners: [],
    country: "India",
  });

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    setFormValues((prevState) => ({
      ...prevState, // Keep other form values unchanged
      country: selectedCountry, // Update the country field
    }));
  };

  const handleOccupationChange = (event) => {
    const { name, checked } = event.target; // Extract name and checked from the checkbox event

    setFormValues((prevValues) => {
      // Get the current array of selected occupations
      const currentOccupations = prevValues.occupation || [];

      // Add the occupation if checked, remove it if unchecked
      const updatedOccupations = checked
        ? [...currentOccupations, name] // Add occupation to array if checked
        : currentOccupations.filter((occupation) => occupation !== name); // Remove if unchecked

      return {
        ...prevValues,
        occupation: updatedOccupations, // Update occupation as an array of selected strings
      };
    });
  };

  const addPartner = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      partners: [
        ...prevValues.partners,
        { first_name: "", last_name: "", email: "", phone_number: "" },
      ],
    }));
  };

  const handlePartnerChange = (index, event) => {
    const newPartners = formValues.partners.map((partner, i) => {
      if (i === index) {
        return Object.assign({}, partner, {
          [event.target.name]: event.target.value,
        });
      }
      return partner;
    });

    setFormValues((prevValues) => ({
      ...prevValues,
      partners: newPartners,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const removePartner = (index) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      partners: prevValues.partners.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};

    if (!formValues.first_name) errors.firstName = "First Name is required";
    if (!formValues.last_name) errors.lastName = "Last Name is required";
    if (!formValues.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(formValues.email)) {
      errors.email = "Invalid email address";
    }
    if (!formValues.company_name)
      errors.companyName = "Company Name is required";
    if (!formValues.phone_number) {
      errors.phone = "Phone Number is required";
    } else if (!validatePhoneNumber(formValues.phone_number)) {
      errors.phone = "Invalid phone number";
    }
    if (!formValues.company_address)
      errors.address = "Company Address is required";
    const isAnyOccupationSelected = formValues.occupation.length > 0; // Check if the array is not empty

    if (!isAnyOccupationSelected) {
      errors.occupation = "Please select at least one occupation.";
    }

    formValues.partners.forEach((partner, index) => {
      if (!partner.first_name)
        errors[`partnerFirstName${index}`] = "First Name is required";
      if (!partner.last_name)
        errors[`partnerLastName${index}`] = "Last Name is required";
      if (!partner.email) {
        errors[`partnerEmail${index}`] = "Email is required";
      } else if (!validateEmail(partner.email)) {
        errors[`partnerEmail${index}`] = "Invalid email address";
      }
      if (!partner.phone_number) {
        errors[`partnerPhone${index}`] = "Phone Number is required";
      } else if (!validatePhoneNumber(partner.phone_number)) {
        errors[`partnerPhone${index}`] = "Invalid phone number";
      }
    });

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setLoggedIn(true);
      try {
        // Send the email in the body of the request

        const response = await axios.post("/auth/verify", {
          email: formValues.email,
          phone_number: formValues.phone_number,
        });

        if (response.status === 200) {
          navigate("/verify", {
            state: {
              email: response.data.data.email,
              data: formValues,
              correctotp: response.data.data.otp,
            },
          });
          setLoggedIn(false);
        }
      } catch (error) {
        if (error.status === 400) {
          setAlertOpen(true);

          setErrorMsg(error.response.data.message);

          setLoggedIn(false);
        }
        console.error(error);
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <BackgroundBox>
        <CssBaseline />
        <GlassContainer component="main" maxWidth="md">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Logo />
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              sx={{ color: "black" }}
              fontWeight="bold"
            >
              Sign up
            </Typography>
            {/* <Lottie animationData={diamondloader} /> */}
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="first_name"
                    required
                    fullWidth
                    id="first_name"
                    label="First Name"
                    autoFocus
                    value={formValues.first_name}
                    onChange={handleInputChange}
                    error={!!formErrors.firstName}
                    helperText={formErrors.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    autoComplete="family-name"
                    value={formValues.last_name}
                    onChange={handleInputChange}
                    error={!!formErrors.lastName}
                    helperText={formErrors.lastName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="company_name"
                    label="Company Name"
                    name="company_name"
                    autoComplete="company-name"
                    value={formValues.company_name}
                    onChange={handleInputChange}
                    error={!!formErrors.companyName}
                    helperText={formErrors.companyName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="phone_number"
                    label="Phone Number"
                    name="phone_number"
                    autoComplete="phone"
                    value={formValues.phone_number}
                    onChange={handleInputChange}
                    error={!!formErrors.phone}
                    helperText={formErrors.phone}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="country-label">
                      Country of Operation
                    </InputLabel>
                    <Select
                      labelId="country-label"
                      id="country"
                      value={country}
                      label="Country of Operation"
                      onChange={handleCountryChange}
                    >
                      <MenuItem value="India">India</MenuItem>

                      <MenuItem value="Thailand">Thailand</MenuItem>
                      <MenuItem value="HongKong">Hong Kong</MenuItem>
                      <MenuItem value="Belgium">Belgium</MenuItem>
                      <MenuItem value="Dubai">Dubai</MenuItem>
                      <MenuItem value="NewYork">New York</MenuItem>
                      <MenuItem value="Israel">Israel</MenuItem>
                      <MenuItem value="Vietnam">Vietnam</MenuItem>
                      <MenuItem value="Cambodia">Cambodia</MenuItem>
                      <MenuItem value="Europe">Europe</MenuItem>
                      <MenuItem value="China">China</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="company_address"
                    label="Company Address"
                    name="company_address"
                    autoComplete="address"
                    value={formValues.company_address}
                    onChange={handleInputChange}
                    error={!!formErrors.address}
                    helperText={formErrors.address}
                    multiline
                    rows={4}
                  />
                </Grid>

                <Grid item xs={12}>
                  {/* Occupation Title */}
                  <Typography
                    component="h6"
                    variant="h6"
                    color={formErrors.occupation ? "error" : "initial"}
                  >
                    Occupation*
                  </Typography>

                  {/* Occupation Checkboxes */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.occupation.importExport}
                        onChange={handleOccupationChange}
                        name="ImportExport"
                      />
                    }
                    label="Import/Export"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.occupation.manufacturer}
                        onChange={handleOccupationChange}
                        name="manufacturer"
                      />
                    }
                    label="Manufacturer"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.occupation.jewellers}
                        onChange={handleOccupationChange}
                        name="Jewellers"
                      />
                    }
                    label="Jewellers"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.occupation.wholeseller}
                        onChange={handleOccupationChange}
                        name="Wholeseller"
                      />
                    }
                    label="Wholeseller"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.occupation.retailers}
                        onChange={handleOccupationChange}
                        name="Retailers"
                      />
                    }
                    label="Retailers"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValues.occupation.broker}
                        onChange={handleOccupationChange}
                        name="Broker"
                      />
                    }
                    label="Broker"
                  />

                  {/* Display error if no occupation is selected */}
                  {formErrors.occupation && (
                    <Typography variant="body2" color="error">
                      {formErrors.occupation}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={addPartner}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    + Add Partner
                  </Button>
                </Grid>
                {formValues.partners.map((partner, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <Divider sx={{ width: "100%", my: 2 }} />}
                    <Grid item xs={12}>
                      <Typography component="h6" variant="h6">
                        Partner {index + 1}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        name="first_name"
                        label="First Name"
                        value={partner.first_name}
                        onChange={(event) => handlePartnerChange(index, event)}
                        error={!!formErrors[`partnerFirstName${index}`]}
                        helperText={formErrors[`partnerFirstName${index}`]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        name="last_name"
                        label="Last Name"
                        value={partner.last_name}
                        onChange={(event) => handlePartnerChange(index, event)}
                        error={!!formErrors[`partnerLastName${index}`]}
                        helperText={formErrors[`partnerLastName${index}`]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="email"
                        label="Email"
                        value={partner.email}
                        onChange={(event) => handlePartnerChange(index, event)}
                        error={!!formErrors[`partnerEmail${index}`]}
                        helperText={formErrors[`partnerEmail${index}`]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="phone_number"
                        label="Phone Number"
                        value={partner.phone_number}
                        onChange={(event) => handlePartnerChange(index, event)}
                        error={!!formErrors[`partnerPhone${index}`]}
                        helperText={formErrors[`partnerPhone${index}`]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => removePartner(index)}
                        sx={{ mt: 1 }}
                      >
                        Delete Partner
                      </Button>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
              <Box
                sx={{
                  width: { xs: "90%", sm: "70%", md: "40%" },
                  margin: "auto",
                }}
              >
                <Collapse in={alertOpen}>
                  <Alert severity="error" onClose={() => setAlertOpen(false)}>
                    {errorMsg}
                  </Alert>
                </Collapse>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <LoadingButton
                  loading={loggedIn}
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    borderRadius: 20,
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#1d7ecc",
                    "&:hover": {
                      backgroundColor: "#1565c0",
                    },
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                    fontWeight: "bold",

                    maxWidth: { xs: "100%", sm: "50%", md: "50%" },
                  }}
                >
                  Sign Up
                </LoadingButton>
              </Box>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link href="/" variant="body2" sx={{ color: "#1b6fb3" }}>
                    Already have an account? Log in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 3, color: "#1b6fb3" }} />
        </GlassContainer>
      </BackgroundBox>
    </ThemeProvider>
  );
}
