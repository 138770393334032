import * as React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardHeader, Divider, useMediaQuery, useTheme } from '@mui/material';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Color } from '../../../Theme/Color';


export default function StockCard() {
    const user = useSelector((state: RootState) => state.user.data)
    const data = [
        { value: user?.stock_in, label: 'Stock In' },
        { value: user?.stock_out, label: 'Stock Out' },
        { value: user?.purchased_diamonds, label: 'Purchased Diamonds' },
    ];
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-start',
                paddingTop: isMobile ? '16px' : '10px',
                marginBottom: isMobile ? '25px' : '0px',
                // marginLeft: isMobile ? '' : 20,
                position: 'relative',


            }}
        >
            <Card sx={{ width: '100%', maxWidth: 300, borderRadius: '8px' }}>
                <CardHeader
                    title="Stock Data"
                    sx={{

                        color: Color.DARK_BLUE, fontFamily: 'Playfair Display, serif',
                        '& .MuiCardHeader-title': { // Applying style specifically to the title
                            // fontFamily: 'Playfair Display, serif',
                            // fontWeight: 600
                        }, fontWeight: 600,

                    }}
                />
                <CardContent>
                    <Grid container spacing={2}>
                        {data.slice(0, 2).map((item, index) => (
                            <Grid item xs={12} sm={6} key={index} style={{ textAlign: 'center' }}>
                                <Typography variant="h5">{item.value}</Typography>
                                <Typography variant="body2" color="textSecondary">{item.label}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={2}>
                        {data.slice(2).map((item, index) => (
                            <Grid item xs={12} style={{ textAlign: 'center' }} key={index}>
                                <Typography variant="h6">{item.value}</Typography>
                                <Typography variant="body2" color="textSecondary">{item.label}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}
