import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Button,
    styled,
    useTheme
} from '@mui/material';
import { BorderColor, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Color } from '../../../Theme/Color';


const CarouselContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '500px',
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: "black",

    '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        height: '100px', // Adjust the height of the gradient
        background: `linear-gradient(
            to bottom,
          rgba(255, 255, 255, 0) 70%,
                    rgba(255, 255, 255, 0.5) 75%,
                    rgba(255, 255, 255, 0.8) 80%,
                    rgba(255, 255, 255, 1) 100%
        )`,
        pointerEvents: 'none', // Ensures it doesn't block interactions
    },

    [theme.breakpoints.down('sm')]: {
        height: '400px',
        backgroundColor: "white",
    },
}));


const TextOverlay = styled(Box)(({ theme }) => ({
    marginLeft: 13,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '500px',
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 5,  // Increased z-index to ensure it's above other elements

    [theme.breakpoints.down('md')]: {
        width: '400px',
        marginTop: 10

    },
}));





const ImageContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '85%', // Default width for larger screens
    height: '100%',
    marginLeft: 'auto', // Push the image to the right
    overflow: 'hidden',
    '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `
            linear-gradient(
                to left,
                rgba(0,0,0, 0) 75%,   
                rgba(0,0,0, 0.3) 85%, 
                rgba(0,0,0, 0.7) 95%, 
                rgba(0,0,0, 1) 100%
            ),linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0) 70%,  
                    rgba(255, 255, 255, 0.5) 80%,
                    rgba(255, 255, 255, 0.8) 90%,
                    rgba(255, 255, 255, 1) 100%
)

                
        `,
        pointerEvents: 'none',
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%', // Full width on mobile screens
        '&::after': {
            background: `
                linear-gradient( 
                    to bottom,
                    rgba(255, 255, 255, 0) 65%,                                
                    rgba(255, 255, 255, 0.9) 80%,
                    rgba(255, 255, 255, 1) 100%
                )
            `,
        },
    },
}));




const SlideContainer = styled(Box)({
    position: 'absolute',
    width: '100%',
    height: '100%',
    transition: 'opacity 1.2s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end', // Align to the right
    overflow: 'hidden',
    pointerEvents: 'none', // Disable pointer events for all slides by default
    zIndex: 1, // Adjust z-index for slides
    '&.active': {
        pointerEvents: 'auto', // Enable pointer events only for the active slide
    },
});

const NavigationButton = styled(IconButton)(({ theme }) => ({

    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    backdropFilter: 'blur(8px)',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
    color: theme.palette.common.white,
    zIndex: 10,
}));

const ContactButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(4),
    borderRadius: 10,
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    borderWidth: '2px',
    padding: '10px 30px',
    fontSize: '1.1rem',

    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(12,60,96, 0.2)',
        borderColor: Color.DARK_BLUE,
        transform: 'translateY(-2px)',
    },
    [theme.breakpoints.down('sm')]: {
        color: Color.DARK_BLUE,
        borderColor: Color.DARK_BLUE,
        padding: '5px',
        marginRight: "37px"

    }

}));

const handleContactClick = () => {
    const phoneNumber = "+919825188599";
    const message = "Please share the catalog of jewellery!";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Open WhatsApp in a new tab
    window.open(url, '_blank');
};





interface Slide {
    image: string;
    title: string;
    description: string;
}

const slides: Slide[] = [
    {
        image: "./sdjewels (1).jpg",
        title: "Timeless Elegance",
        description: "Handcrafted diamond collections that speak to your soul"
    },
    {
        image: "./sdjewels (2).jpg",
        title: "Royal Heritage",
        description: "Precious gemstones set in traditional craftsmanship"
    },
    {
        image: "./sdjewels (3).jpg",
        title: "Modern Luxe",
        description: "Contemporary designs for the modern connoisseur"
    },

];

const JewelryCarousel: React.FC = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 5000);
        return () => clearInterval(timer);
    }, []);

    const handlePrevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    const handleNextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    return (
        <CarouselContainer>
            <TextOverlay>
                <Typography
                    variant="h3"
                    component="h2"
                    sx={{
                        fontFamily: "'Cormorant Garamond', serif",
                        color: theme.palette.common.white,
                        mb: 2,
                        letterSpacing: '0.05em',
                        textShadow: '0 2px 4px rgba(0,0,0,0.5)',
                        [theme.breakpoints.down('sm')]: {
                            color: theme.palette.common.white,
                        },
                    }}

                >
                    {slides[currentSlide].title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{
                        color: theme.palette.common.white,
                        opacity: 0.9,
                        fontWeight: 300,
                        letterSpacing: '0.02em',
                        mb: 2,
                        textShadow: '0 1px 2px rgba(0,0,0,0.1)',
                        [theme.breakpoints.down('sm')]: {
                            color: theme.palette.common.white, // Color turns white on mobile
                        },
                    }}
                >
                    {slides[currentSlide].description}
                </Typography>
                <ContactButton variant="outlined" size="large" onClick={handleContactClick}
                >
                    Contact Us
                </ContactButton>
            </TextOverlay>

            {slides.map((slide, index) => (
                <SlideContainer
                    key={index}
                    sx={{
                        opacity: index === currentSlide ? 1 : 0,
                        pointerEvents: index === currentSlide ? 'auto' : 'none',
                    }}
                >
                    <ImageContainer>
                        <Box
                            component="img"
                            src={slide.image}
                            alt={slide.title}
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                        />
                    </ImageContainer>
                </SlideContainer>
            ))}

            <NavigationButton
                onClick={handlePrevSlide}
                sx={{ left: theme.spacing(2) }}
            >
                <ChevronLeft />
            </NavigationButton>

            <NavigationButton
                onClick={handleNextSlide}
                sx={{ right: theme.spacing(2) }}
            >
                <ChevronRight />
            </NavigationButton>
        </CarouselContainer>
    );
};

export default JewelryCarousel;