import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from 'react-redux';
import Link from "@mui/material/Link";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { LockOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

import { styled } from '@mui/system';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { LoginRequestType } from "../api/types/LoginRequestType";
import { loggedIn, loggedOut } from "../../../store/slice/authSlice";
import * as loginApi from '../api/loginApi';
import * as fetchUserApi from '../api/fetchUserApi';
import { setUserData } from "../../../store/slice/userSlice";
import Logo from "../../../Component/Logo";
import { LoadingButton } from '@mui/lab';


const defaultTheme = createTheme();

const MatteGlassBackground = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.2)', // Adjust the opacity as desired
  backdropFilter: 'blur(100px)', // Adjust the blur value as desired
  WebkitBackdropFilter: 'blur(100px)', // For Safari compatibility
}));

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="">
        Superb Diamonds
      </Link>{" "}

      {" | "}
      <Link color="inherit" href="">
        Twinkle Diam
      </Link>{" "}
      {2024}
    </Typography>
  );
}

const Login: React.FC = () => {
  const handleClick = () => {
    navigate('/signup');
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const errorMessage = useSelector((st: RootState) => st.user.error);

  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<{ login_variable?: string; password?: string }>({});
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const login_variable = data.get("login_variable") as string;
    const password = data.get("password") as string;

    // Validate login credentials
    const newErrors: { login_variable?: string; password?: string } = {};
    if (!login_variable) newErrors.login_variable = "Login credential is required";
    if (!password) newErrors.password = "Password is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    else
      setErrors({});

    const userData: LoginRequestType = {
      login_variable,
      password,
    };

    try {
      setLoading(true);
      const res = await loginApi.login(userData);

      if (res) {
        dispatch(loggedIn());

        const userRes = await fetchUserApi.fetchUser(login_variable);
        dispatch(setUserData(userRes));
        navigate("/home");
        setLoading(false)
      } else {
        throw new Error('Login failed. Please check your credentials.');
      }
    } catch (error) {
      // Cast error to 'Error' to access the message property
      const errMessage = (error as Error).message;
      // dispatch(setUserError(errMessage));
      setErrorMsg(errMessage);
      dispatch(loggedOut());
      setLoading(false)
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh", position: "relative" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            backgroundImage: 'url(/login2.avif)',
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          }}
        />
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={MatteGlassBackground}
            elevation={6}
            square
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Logo />
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Log in
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <Collapse in={!!errorMsg}>
                  <Alert severity="error" onClose={() => setErrorMsg('')}>
                    {errorMsg}
                  </Alert>
                </Collapse>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number Or Username"
                  name="login_variable"
                  autoComplete="login_variable"
                  error={!!errors.login_variable}
                  helperText={errors.login_variable}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={errors.password}
                />
                <LoadingButton
                  loading={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Log In
                </LoadingButton>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleClick} style={{
                      textTransform: 'none',
                      background: 'none',
                      padding: 0,
                      border: 'none',
                      boxShadow: 'none',
                      color: '#1976d2',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}>
                      Don't have an account? Sign Up
                    </Button>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
