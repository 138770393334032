import React from "react";
import { GridColDef } from '@mui/x-data-grid';
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from 'react-redux';
import { DiamondDataGrid } from "../../../Component/DiamondDataGrid";
import SavedSearch from "../Components/SavedSearch";
import { DashboardCardRender } from "../Components/DashboardCard";
import StockCard from "../Components/StockCard";
import SkeletonComponent from "../../../Component/SkeletonComponent";
import { Color } from "../../../Theme/Color";
import AdCard from "../Components/AdCard";
import JewelryCarousel from "../Components/JewelryCarousel";

interface SearchData {
  title: string;
  date: string;
  shapes: string[];
  caratRange: string;
  color: string;
  clarity: string;
  cut: string;
  polish: string;
  symmetry: string;
}

const Home: React.FC = () => {
  const dispatch = useDispatch();

  const searchDataArray: SearchData[] = [
    {
      title: "5.4 defij if-vvs1",
      date: "09/08/2018 7:55",
      shapes: ["Round"],
      caratRange: "0.57 - 0.65",
      color: "D,E,F,I,J",
      clarity: "FL,IF,VVS1",
      cut: "EX",
      polish: "EX,VG",
      symmetry: "EX,VG",
    },
    {
      title: "5.4 defij if-vvs1",
      date: "18/01/2018 11:39",
      shapes: ["Round", "Princess", "Pear", "Marquise", "Emerald"],
      caratRange: "0.57 - 0.63",
      color: "D,E,F,I,J",
      clarity: "FL,IF,VVS1",
      cut: "EX",
      polish: "EX,VG",
      symmetry: "EX,VG",
    },
    // Add more search items as needed
  ];
  const gridColumns: GridColDef[] = [
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'certino', headerName: 'CertiNo', width: 150 },
    { field: 'stock', headerName: 'Stock', width: 150 },
    { field: 'invoice', headerName: 'Invoice', width: 150 },
    { field: 'age', headerName: 'Age', width: 100 },
    { field: 'color', headerName: 'Color', width: 100 },
    { field: 'purity', headerName: 'Purity', width: 100 },
    { field: 'rap', headerName: 'Rap', width: 100 },
    { field: 'weight', headerName: 'Weight', width: 100 },
    { field: 'shape', headerName: 'Shape', width: 150 },
    { field: 'location', headerName: 'Location', width: 150 },
    { field: 'cut', headerName: 'Cut', width: 150 },
    { field: 'polish', headerName: 'Polish', width: 150 },
    { field: 'symmetry', headerName: 'Symmetry', width: 150 },
    { field: 'fluorescent', headerName: 'Fluorescent', width: 150 },
    { field: 'length', headerName: 'Length', width: 150 },
    { field: 'width', headerName: 'Width', width: 150 },
    { field: 'diameter', headerName: 'Diameter', width: 150 },
    { field: 'party', headerName: 'Party', width: 150 },
    { field: 'lab', headerName: 'Lab', width: 150 },
    { field: 'tr_cost', headerName: 'Tr Cost', width: 150 },
    { field: 'tr_gst', headerName: 'Tr GST', width: 150 },
    { field: 'additional_exp', headerName: 'Additional Exp', width: 150 },
    { field: 'total_cost', headerName: 'Total Cost', width: 150 },
    { field: 'brokerage', headerName: 'Brokerage', width: 150 },
    { field: 'comment', headerName: 'Comment', width: 150 },
    { field: 'td', headerName: 'T.D', width: 150 },
  ];


  interface Data {
    id: number;
    date: string;
    certino: number;
    stock: number;
    invoice: number;
    age: number;
    color: string;
    purity: string;
    rap: number;
    weight: number;
    shape: string;
    location: string;
    cut: string;
    polish: string;
    symmetry: string;
    fluorescent: string;
    length: number;
    width: number;
    diameter: number;
    party: string;
    lab: string;
    tr_cost: number;
    tr_gst: number;
    additional_exp: number;
    total_cost: number;
    brokerage: number;
    comment: string;
    td: number;
  }


  const data: Data[] = [
    {
      "id": 1,
      "date": "2023-01-01",
      "certino": 123,
      "stock": 10,
      "invoice": 1,
      "age": 2,
      "color": "D",
      "purity": "VVS1",
      "rap": 100,
      "weight": 1.0,
      "shape": "Round",
      "location": "Mumbai",
      "cut": "Excellent",
      "polish": "Excellent",
      "symmetry": "Excellent",
      "fluorescent": "None",
      "length": 5.5,
      "width": 5.5,
      "diameter": 3.5,
      "party": "Party A",
      "lab": "GIA",
      "tr_cost": 1000,
      "tr_gst": 100,
      "additional_exp": 50,
      "total_cost": 1150,
      "brokerage": 10,
      "comment": "None",
      "td": 1
    },
  ];
  const images = [
    './login2.avif',
    './login2.avif',
    './login2.avif'
  ];
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {/* <Container> */}
      <Grid container flexDirection={'column'} marginTop={2} >


        {/* <Grid container flexDirection="row" spacing={1} marginBottom={isPhone ? 0 : 4}>
            <Grid item xs={isPhone ? 12 : 3.5}>
              <StockCard />
            </Grid>
            <Grid item xs={8.5}> 
              {!isPhone && <AdCard images={images} interval={5000} />} 
            </Grid>
          </Grid> */}

        <Grid item>
          <DashboardCardRender />
        </Grid>
        <Grid item>
          <SavedSearch data={searchDataArray} />
        </Grid>
        <Grid item>
          <DiamondDataGrid columns={gridColumns} rows={data} title={'Recent Purchases'} />
        </Grid>
      </Grid>
      {/* </Container> */}
    </>
  );
};

export default Home;
