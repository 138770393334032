import React, { useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Box,
    IconButton,
    Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { looseTableSpec, tableSpec } from "../Constants/FilterFormItems";


interface StockData {
    invoice_no: string;
    deal_date: string;
    shape: string;
    weight: number;
    broker_name: string;
    party_name: string;
    purchase_carat: number;
    location: string;
    days: number;
    sieve: string;
    number_of_stones: string;
    total_price: number;
    comment: string;
    in_stock: number;
}

interface StockSize {
    [key: string]: number;
}

const stockSize: StockSize = {
    deal_date: 1.5,
    invoice_no: 1.5,
    shape: 1,
    weight: 1,
    broker_name: 1.5,
    party_name: 1.5,
    purchase_carat: 1,
    location: 1.5,
    days: 1,
    sieve: 1.5,
    number_of_stones: 1,
    total_price: 1,
    comment: 1.5,
};

const capitalizeFirstLetter = (text: string) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
};

const formatDate = (dateInput: string | number | Date) => {
    const date = new Date(dateInput);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const LooseStockDataCard = ({ stockData }: { stockData: StockData }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const handleExpandClick = () => setExpanded(!expanded);

    const dealDate = formatDate(stockData.deal_date);

    return (
        <Card sx={{ width: "100%", boxShadow: 3, borderRadius: 2, mb: 2, position: "relative" }}>
            {stockData.in_stock === 0 && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 9,
                        right: -9,
                        backgroundColor: "error.main",
                        color: "white",
                        padding: "4px 20px",
                        transform: "rotate(25deg)",
                        zIndex: 1,
                        borderRadius: 1,
                        boxShadow: 2,
                    }}
                >
                    <Typography variant="body2">Sold</Typography>
                </Box>
            )}

            <CardContent sx={{ margin: 0 }}>
                {/* Date and Icon */}
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <CalendarTodayIcon sx={{ fontSize: 17, mr: 1 }} />
                    <Typography variant="caption" color="text.secondary" fontSize={{ xs: 12, sm: 14, md: 15 }}>
                        {dealDate}
                    </Typography>
                </Box>

                {/* Main Grid Container */}
                <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
                    {looseTableSpec.slice(1, 10).map((key, index) => (
                        <Grid
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            lg={stockSize[key]} // Use provided size for larger screens
                            key={index}
                            textAlign="center"
                        >
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: { xs: 14, sm: 16, md: 18 } }}>
                                {stockData[key as keyof StockData] || "-"}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                {capitalizeFirstLetter(key.replace(/_/g, " "))}
                            </Typography>
                        </Grid>
                    ))}

                    {/* Expand Icon Button */}
                    <Grid item xs="auto" sx={{ justifyContent: "flex-end", alignSelf: "center" }}>
                        <IconButton
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            sx={{
                                justifyContent: "right",
                                transition: "transform 0.3s",
                                transform: expanded ? "rotate(180deg)" : "rotate(0deg)"
                            }}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                {/* Expanded View */}
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Grid container rowSpacing={2} columnSpacing={2} sx={{ mt: 2 }} justifyContent="center">
                        {Object.keys(stockData)
                            .filter(key => !["__v", "id", "deal_date", "user_email", "in_stock", "due_date"].includes(key))
                            .slice(12)
                            .map(key => (
                                <Grid
                                    item
                                    xs={6} // Two items per row on mobile
                                    sm={5.5} // Two items per row on tablets
                                    key={key}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid grey",
                                        paddingBottom: 1,
                                    }}
                                >
                                    <Typography variant="subtitle1">
                                        {capitalizeFirstLetter(key.replace(/_/g, " "))}
                                    </Typography>
                                    <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">
                                        {stockData[key as keyof StockData] || "-"}
                                    </Typography>
                                </Grid>
                            ))}
                    </Grid>
                </Collapse>
            </CardContent>
        </Card>
    );
};

export default LooseStockDataCard;
