import React from 'react';
import { Card, CardContent, Box, Stack } from '@mui/material';
import { Skeleton } from '@mui/material';

const SkeletonData = () => {
    return (
        <Card sx={{ width: "100%", boxShadow: 3, borderRadius: 2, mb: 2 }}>
            <CardContent sx={{ padding: 2 }}>
                <Stack
                    direction="row"
                    spacing={4}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ overflowX: 'auto' }}
                >
                    <Skeleton variant="text" width={100} height={24} />
                    {[...Array(7)].map((_, index) => (
                        <Box key={index} sx={{ textAlign: "center", minWidth: '80px' }}>
                            <Skeleton variant="text" width={40} height={20} />
                            <Skeleton variant="text" width={60} height={16} />
                        </Box>
                    ))}
                </Stack>
            </CardContent>
        </Card>
    );
};

export default SkeletonData;