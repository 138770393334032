import axios from "axios";

import { UserResponseType } from "./types/UserResponseType";

export const signup = async (prop: UserResponseType): Promise<any> => {
  try {
    const res = await axios.post("/auth/signup", {
      first_name: prop?.first_name,
      last_name: prop?.last_name,
      email: prop?.email,
      user_name: prop?.user_name,
      phone_number: prop?.phone_number,
      password: prop?.password,
      confirm_password: prop?.confirm_password,
      company_name: prop?.company_name,
      company_address: prop?.company_address,
      occupation: prop?.occupation,
      country: prop?.country,
      partners: prop?.partners,
      start_date: prop?.start_date,
      update_date: prop?.update_date,
      end_date: prop?.end_date,
      stock_in: prop?.stock_in,
      stock_out: prop?.stock_out,
      purchased_diamonds: prop?.purchased_diamonds,
      last_stock_id: prop?.last_stock_id,
      invoice_no: prop?.invoice_no,
    });
    return res;
  } catch (error: any) {
    if (error.status === 400) {
      return error;
    }
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const errorMessage =
          error?.response?.data?.message ||
          "Sorry! server is under maintenance";
        throw new Error(errorMessage);
      }
    } else {
      throw new Error("Unknown Error");
    }
  }
};
