import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CustomBreadcrumb from '../Component/CustomBreadCrumb';
import { useNavigate } from 'react-router';
import { SideBarItems } from '../Constants/SidebarItems';
import { CustomerSupportItems } from '../Constants/CustomerSupportItems';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { UserState } from '../store/slice/userSlice';
import { RootState } from '../store/store';
import { Color } from '../Theme/Color';
import DiamondIcon from '@mui/icons-material/Diamond';

const drawerWidth = 280;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3), // Default padding for larger screens
  display: 'flex', // Add flex display for alignment
  justifyContent: 'center', // Center horizontally
  alignItems: 'center', // Center vertically (if needed)
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,


  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0),
  },


  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function SideNav({ children }: { children: React.ReactNode }) {
  const userData = useSelector((state: RootState) => state.user.data);

  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const settings = ['Profile', 'Dashboard', 'Logout'];
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleCloseUserMenu = (item: any) => {
    if (item == "Profile") {
      navigate('/profile')
    }
    else if (item == "Dashboard") {
      navigate('/home')
    }
    else if (item == "Logout") {
      navigate('/')
    }
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);

  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: Color.DARK_BLUE }}>
        <Toolbar >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* <img src="./LOGO.jpg" alt="Logo" style={{ marginRight: 16 }} /> */}
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            {useMediaQuery(theme.breakpoints.down('sm')) ? (
              <DiamondIcon fontSize="large" /> // Show diamond icon on small screens
            ) : (
              <Typography
                sx={{ fontFamily: 'Cormont Infant', textAlign: 'center' }}
                variant="h5"
                noWrap
                component="div"
              >
                SuperbDiamonds
                <DiamondIcon sx={{ mx: 1 }} />
                TwinkleDiam
              </Typography>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }} />

          <IconButton
            color="inherit"
            aria-controls="admin-menu"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
          >

            <Avatar alt={userData?.first_name} src='./' />
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}

          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerOpen}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {SideBarItems.map((item, index) => {
            return (
              item.icon ? (
                <ListItem key={item.name} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    onClick={() =>
                      navigate(`/${item.name.replace(/\s+/g, "-").toLowerCase()}`)
                    }
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ) : (
                item.map && item.breadcrumbIcon &&
                <CustomBreadcrumb key={item.name} parentName={item.name} linkTextMap={item.map} iconMap={item.breadcrumbIcon} />
              )
            )
          })}
        </List>
        <Divider />
        <List>
          {CustomerSupportItems.map((item, index) => (
            <ListItem key={item.name} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() =>
                  navigate(`/${item.name.replace(/\s+/g, "-").toLowerCase()}`)
                }

                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <Box sx={{ width: '100%' }}>
          {children}
        </Box>
      </Main>


    </Box>
  );
}



