import * as React from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Collapse,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as signupApi from '../api/signupApi';

import { Visibility, VisibilityOff, LockOutlined } from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useLocation, useNavigate } from "react-router";
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    background: {
      default: 'transparent',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

const PasswordPage: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const location = useLocation();
  const [errorMsg, setErrorMsg] = React.useState<string>('');

  const navigate = useNavigate();
  const { data } = location.state;
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault();
  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => setAcceptTerms(event.target.checked);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setErrorMsg("Passwords do not match!")
      setAlertOpen(true);
    } else {
      setAlertOpen(false);
      try {

        const signupData = {
          ...data,
          password: password,
          user_name: username,
          confirm_password: confirmPassword,
        };

        // Call the signup API with the updated data
        const res = await signupApi.signup(signupData);
        if (res.status === 201) {
          navigate('/')
        }
        if (res.status === 400) {

          setAlertOpen(true)
          setErrorMsg(res.response.data.message)
        }


      } catch (error: any) {

        console.error("check error", error);
      }
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: 'url("/login2.avif")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              backdropFilter: 'blur(10px)',
              borderRadius: '16px',
            }}
          >

            <Typography component="h1" variant="h5" fontWeight="bold">
              Create Account
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              {/* <Collapse in={alertOpen}>
                <Alert severity="error" sx={{ mb: 2 }}>
                  Passwords do not match!
                </Alert>
              </Collapse> */}
              <Box sx={{ marginBottom: 3 }}>

                <Collapse in={alertOpen}>
                  <Alert severity="error" onClose={() => setAlertOpen(false)}>
                    {errorMsg}
                  </Alert>
                </Collapse>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="username"
                    label="Username"
                    id="username"
                    autoComplete="user-name"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showPassword ? "text" : "password"}
                    id="confirmPassword"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="primary"
                    onClick={handleOpenDialog}
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    View Terms and Conditions
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acceptTerms}
                        onChange={handleTermsChange}
                        color="primary"
                      />
                    }
                    label="I accept all terms and conditions"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1, py: 1.5, fontSize: '1.1rem', fontWeight: 'bold' }}
                disabled={!acceptTerms}
              >
                Try free 14 day trial
              </Button>
              <Button
                fullWidth
                variant="outlined"
                sx={{ mb: 1, py: 1.5, fontSize: '1.1rem' }}
                href="/plans"
              >
                View plans
              </Button>
            </Box>
          </Paper>
        </Container>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        PaperProps={{
          sx: {
            borderRadius: '16px',
            maxWidth: '600px',
          }
        }}
      >
        <DialogTitle id="dialog-title" sx={{ fontWeight: 'bold' }}>Terms and Conditions</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description" sx={{ mb: 2 }}>
            By accessing or using our inventory management system of Superb Diamonds,
            you agree to be bound by these terms and conditions. If you do not agree
            to these terms, you may not use the Product.
          </DialogContentText>
          <DialogContentText id="dialog-description" sx={{ mb: 2 }}>
            You are responsible for ensuring the accuracy and completeness of all
            data entered into the Product. We provide the Product on an "as-is" and
            "as-available" basis, and we make no warranties or guarantees regarding
            its performance, reliability, or suitability for your specific needs.
          </DialogContentText>
          <DialogContentText id="dialog-description" sx={{ mb: 2 }}>
            All data entered into the Product is your sole responsibility. We do
            not monitor or verify the data you enter, and we are not liable for
            any inaccuracies, errors, or omissions in your data. You are responsible
            for regularly backing up your data to prevent loss.
          </DialogContentText>
          <DialogContentText id="dialog-description">
            We implement standard security measures to protect your data; however,
            we cannot guarantee absolute security. In the event of a data breach,
            loss, or unauthorized access due to unforeseen circumstances, third-party
            actions, or any other undue reasons, we will not be held liable for any
            resulting damages or losses. You acknowledge and accept the risk
            associated with storing your data in the Product.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default PasswordPage;