import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button } from "@mui/material";
import { DiamondDataGrid } from "../../Component/DiamondDataGrid";
import FilterForm from "../../Component/FilterForm";
import HeaderTitle from "../../Component/HeaderTitle";

const Stock: React.FC = () => {
    const [gridColumns, setGridColumns] = useState([]);
    const [data, setData] = useState([]);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>


            <FilterForm />

        </Box>
    );
};

export default Stock;
