import React from "react";
import UnderConstruction from "../../Component/UnderConstruction";

const PacketPrinting: React.FC = () => {
  return (
    <UnderConstruction />
  );
};

export default PacketPrinting;
