import React from "react";
import UnderConstruction from "../../Component/UnderConstruction";

const CertifiedDiamonds: React.FC = () => {
    return (

        <UnderConstruction />
    );
};

export default CertifiedDiamonds;
