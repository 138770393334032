import React, { useState } from "react";
import {
    IconButton,
    Modal,
    Box,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
    Divider
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchIcon from '@mui/icons-material/Search';
import DiamondIcon from '@mui/icons-material/Diamond';
import SellIcon from '@mui/icons-material/Sell';
import ReceiptIcon from '@mui/icons-material/Receipt';

const InstructionsModal = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const instructions = [
        { icon: <CalendarTodayIcon />, text: "Click on 'Daily Report' for the last 24 hours of diamond data." },
        { icon: <DateRangeIcon />, text: "Use 'Quarterly Report' for the last quarter's data." },
        { icon: <DateRangeIcon />, text: "Select 'Yearly Report' for data from the last year." },
        { icon: <SearchIcon />, text: "Search by specific date range using 'From' and 'To' dates." },
        { icon: <DiamondIcon />, text: "View all purchased diamonds with 'View All Purchased Diamonds'." },
        { icon: <DiamondIcon />, text: "Check current stock with 'View All In Stock Diamonds'." },
        { icon: <SellIcon />, text: "See sold diamonds by clicking 'View All Sold Diamonds'." },
        { icon: <ReceiptIcon />, text: "Search by invoice, stock ID, or certificate ID using the search boxes." }
    ];

    return (
        <div>
            <IconButton color="primary" aria-label="info" onClick={handleOpen}>
                <InfoOutlinedIcon />
            </IconButton>

            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h5" component="h2" gutterBottom fontWeight="bold" color="primary">
                        How to Use the Reports Tab
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <List>
                        {instructions.map((instruction, index) => (
                            <ListItem key={index} alignItems="flex-start">
                                <ListItemIcon>
                                    {instruction.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={instruction.text}
                                    primaryTypographyProps={{ variant: 'body1' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Divider sx={{ my: 2 }} />
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2, fontWeight: 'bold' }}
                    >
                        Got it!
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default InstructionsModal;