import React, { useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import DiamondIcon from "@mui/icons-material/Diamond";
import SellIcon from "@mui/icons-material/Sell";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import axios from "axios";
import CustomSnackBar, { snackbarType } from "../../../Component/CustomSnackBar";

interface DiamondActionsProps {
    onActionGenerated: (data: any) => void;  // Callback prop for data transfer
    stocktype: string
}

const DiamondActions: React.FC<DiamondActionsProps> = ({ onActionGenerated, stocktype }) => {
    const [onSuccess, setOnSuccess] = useState<snackbarType>({ success: false, message: '' });

    const fetchDiamonds = async (type: string) => {
        try {
            console.log("check the type", type)
            const response = await axios.get(`/${stocktype}/view-allstock?type=${type}`);

            if (response.status === 200) {
                onActionGenerated(response.data);
                setOnSuccess({ message: "Data fetched successfully!", success: true, variant: "success" });
            }
        } catch (error: any) {
            if (error.response && (error.response.status === 400 || error.response.status === 500)) {
                onActionGenerated([]);
                setOnSuccess({ message: error.response.data.message, success: true, variant: "error" });
            } else {
                console.error("Error fetching diamonds:", error);
            }
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
                <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<DiamondIcon />}
                        sx={{ py: 1.5 }}
                        onClick={() => fetchDiamonds("stock_in")}
                    >
                        View All In Stock Diamonds
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        startIcon={<SellIcon />}
                        sx={{ py: 1.5 }}
                        onClick={() => fetchDiamonds("stock_out")}
                    >
                        View All Sold Diamonds
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        startIcon={<ShoppingCartIcon />}
                        sx={{ py: 1.5 }}
                        onClick={() => fetchDiamonds("purchased_diamonds")}
                    >
                        View All Purchased Diamonds
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DiamondActions;
