import React from "react";
import UnderConstruction from "../../Component/UnderConstruction";

const ViewPendingPackets: React.FC = () => {
  return (
    <UnderConstruction />
  );
};

export default ViewPendingPackets;
