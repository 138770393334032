import React from 'react'
import UnderConstruction from '../../Component/UnderConstruction'
import { Box } from '@mui/material'

function FileUploader() {
    return (
        <Box sx={{ alignItems: 'center' }}>
            <UnderConstruction />
        </Box>
    )
}

export default FileUploader