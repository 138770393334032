import React, { ChangeEvent, useEffect, useState, useContext } from 'react';
import { Box, TextField, Button, Typography, Grid, InputAdornment, MenuItem, Select, SelectChangeEvent, FormControl, InputLabel } from '@mui/material';
import AddNewBrokerModal from './AddNewBrokerModal';
import AddNewPartyModal from './AddNewPartyModal';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { PurchaseContext } from '../Context/purchaseProviderContext';
import { setUserError } from '../../../store/slice/userSlice';
import AddIcon from '@mui/icons-material/Add';
import CustomSnackBar, { snackbarType } from '../../../Component/CustomSnackBar';
const countryOptions = [
    { value: 'INR', label: 'India', flag: 'https://flagcdn.com/w20/in.png' },
    { value: 'USD', label: 'United States', flag: 'https://flagcdn.com/w20/us.png' },
    { value: 'EUR', label: 'Euro', flag: 'https://flagcdn.com/w20/eu.png' },
    { value: 'JPY', label: 'Japan', flag: 'https://flagcdn.com/w20/jp.png' },
    { value: 'THB', label: 'Thailand', flag: 'https://flagcdn.com/w20/th.png' },
    { value: 'HKD', label: 'Hong Kong', flag: 'https://flagcdn.com/w20/hk.png' },

    { value: 'AED', label: 'Dubai', flag: 'https://flagcdn.com/w20/ae.png' },

    { value: 'ILS', label: 'Israel', flag: 'https://flagcdn.com/w20/il.png' },
    { value: 'VND', label: 'Vietnam', flag: 'https://flagcdn.com/w20/vn.png' },
    { value: 'KHR', label: 'Cambodia', flag: 'https://flagcdn.com/w20/kh.png' },
    { value: 'CNY', label: 'China', flag: 'https://flagcdn.com/w20/cn.png' },

];

const PurchaseInvoice = () => {

    const user = useSelector((state: RootState) => state.user.data);
    const numericStockIDPart = user?.last_stock_id && parseInt(user.last_stock_id.replace(/\D/g, '')) || 0;
    const incrementedNumericPart = numericStockIDPart + 1;
    const updatedStockId = user?.last_stock_id && `${user.last_stock_id.replace(/\d+/g, '')}${incrementedNumericPart}`;
    const dispatch = useDispatch();
    const InvoicePrefix = user?.invoice_no?.replace(/\d+$/, '') || '';
    const InvoiceNumber = user?.invoice_no?.match(/\d+$/)?.[0] || '0';
    const incrementedNumber = (parseInt(InvoiceNumber, 10) + 1).toString().padStart(InvoiceNumber.length, '0');
    const NewInvoice = InvoicePrefix + incrementedNumber

    console.log(NewInvoice); // Logs the new invoice, e.g., "CER-INV1" -> "CER-INV2"


    const { filterFormState, filterFormDispatch } = useContext(PurchaseContext);

    const [filterFormData, setFilterFormData] = useState<any>(filterFormState);

    useEffect(() => {
        setFilterFormData(filterFormState);
    }, [filterFormState]);

    useEffect(() => {
        fetchCountryRates();


    }, []);
    const [openPartyModal, setOpenPartyModal] = useState<boolean>(false);
    const [openBrokerModal, setOpenBrokerModal] = useState<boolean>(false);
    const [countryRates, setCountryRates] = useState<any>({});//
    const [selectedCountry, setSelectedCountry] = useState(countryOptions[0].value);
    const [convertedRate, setConvertedRate] = useState<number>();
    const [onSuccess, setOnSuccess] = useState<snackbarType>({ success: false, message: '' });





    const handleOpenPartyModal = () => setOpenPartyModal(true);
    const handleClosePartyModal = () => setOpenPartyModal(false);

    const handleOpenBrokerModal = () => setOpenBrokerModal(true);
    const handleCloseBrokerModal = () => setOpenBrokerModal(false);

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const handleInvoice = () => {
        filterFormDispatch({ type: 'SET_FILTER_FORM', payload: filterFormData });
        setOnSuccess({ success: true, message: 'Purchase Invoice' });
    }
    const handleInputChange = (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (fieldName === 'deal_date') {
            const date = new Date(value);


            const formattedDate = date.toISOString().split('T')[0]; // Extract only the date part
            // console.log("check", formattedDate)
            setFilterFormData((filterFormData: any) => ({
                ...filterFormData,
                [fieldName]: date
            }));
        } else {
            setFilterFormData((filterFormData: any) => ({
                ...filterFormData,
                [fieldName]: value
            }));
        }
    };

    const handleCountryChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        setSelectedCountry(selectedValue);
        const selectedCountryDetails = countryOptions.find((country) => country.value === selectedValue);
        if (selectedCountryDetails) {
            const newConvertedRate = countryRates[selectedValue];

            setConvertedRate(newConvertedRate);

            setFilterFormData((filterFormData: any) => ({
                ...filterFormData,
                currency_rate: newConvertedRate,
                location: selectedCountryDetails.label
            }));
            // Optionally dispatch the updated form state
            // filterFormDispatch({ type: 'SET_FILTER_FORM', payload: { ...filterFormData, currency_rate: newConvertedRate, location: selectedCountryOption.label } });
        }
    };

    const fetchCountryRates = async () => {
        try {
            const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/USD`, {
                withCredentials: false
            });

            if (response) {

                setCountryRates(response.data.rates);
                setConvertedRate(response.data.rates['INR']);

                filterFormDispatch({ type: 'SET_FILTER_FORM', payload: { ...filterFormData, last_stock_id: updatedStockId, currency_rate: convertedRate || response.data.rates['INR'], deal_date: filterFormData.deal_date || new Date(), location: "India" } });
                // setFilterFormData((filterFormData: any) => ({
                //     ...filterFormData,

                // }));


            }
        } catch (error) {
            dispatch(setUserError("Currency rate not found"));
        }
    };



    return (
        <Box sx={{ padding: 2, backgroundColor: '#d9e3ef', borderRadius: 2 }}>
            <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />

            <Typography variant="h6" gutterBottom>Purchase Invoice</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        style={{ borderRadius: 4, backgroundColor: "white" }}
                        label="Invoice No."
                        fullWidth

                        defaultValue={NewInvoice}
                        onChange={handleInputChange('invoice_no')}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Deal Date"
                                fullWidth
                                defaultValue={getCurrentDate()}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                onChange={handleInputChange('deal_date')}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Party Name"
                                fullWidth
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <Button variant="contained" color="primary" onClick={handleOpenPartyModal}>Add New</Button>
                                //         </InputAdornment>
                                //     ),
                                // }}
                                onChange={handleInputChange('party_name')}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Brokerage"
                                fullWidth
                                onChange={handleInputChange('brokerage')}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Broker Name"
                                fullWidth
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <Button variant="contained" color="primary" onClick={handleOpenBrokerModal}>Add New</Button>
                                //         </InputAdornment>
                                //     ),
                                // }}
                                onChange={handleInputChange('broker')}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth style={{ borderRadius: 4, backgroundColor: "white" }}>
                                <InputLabel>Location</InputLabel>
                                <Select
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    label="Location"
                                    renderValue={(value) => (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={countryOptions.find(option => option.value === value)?.flag}
                                                alt={countryOptions.find(option => option.value === value)?.label}
                                                style={{ marginRight: '8px', width: '24px', height: '16px' }}
                                            />
                                            {countryOptions.find(option => option.value === value)?.label}
                                        </Box>
                                    )}
                                >
                                    {countryOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={option.flag}
                                                    alt={option.label}
                                                    style={{ marginRight: '8px', width: '24px', height: '16px' }}
                                                />
                                                {option.label}
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Converted Rate"
                                fullWidth
                                value={convertedRate || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Days"
                                fullWidth
                                onChange={handleInputChange('days')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                label="Less1%"
                                fullWidth
                                onChange={handleInputChange('less1')}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        style={{ borderRadius: 4, backgroundColor: "white" }}
                        label="GST(%)"
                        fullWidth
                        onChange={handleInputChange('gst')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        style={{ borderRadius: 4, backgroundColor: "white" }}
                        label="Invoice Total"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        style={{ borderRadius: 4, backgroundColor: "white" }}
                        label="Comments"
                        fullWidth
                        multiline
                        rows={2}
                        onChange={handleInputChange('invoice_cmnt')}

                    />
                    <Box display="flex" gap={2} flexDirection="column" alignItems="center" p={2}>

                        <Button variant="contained" color="primary" onClick={handleInvoice}>
                            <AddIcon sx={{ marginRight: 1 }} />
                            Add Invoice
                        </Button>
                    </Box>
                </Grid>


            </Grid>

            <AddNewPartyModal open={openPartyModal} onClose={handleClosePartyModal} />
            <AddNewBrokerModal open={openBrokerModal} onClose={handleCloseBrokerModal} />
        </Box>
    );
};

export default PurchaseInvoice;
