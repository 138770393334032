import React from 'react';
import {
    Box,
    Alert,
    AlertTitle,
    Container,
    Paper,
    useTheme,
    useMediaQuery
} from '@mui/material';

const UnderConstruction: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.background.default
            }}
        >
            <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Paper
                    elevation={3}
                    sx={{
                        p: isMobile ? 2 : 4,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: 400,
                            mb: 3,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <img
                            src="./underconstruction.jpg"
                            alt="Under Construction"
                            style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: theme.shape.borderRadius
                            }}
                        />
                    </Box>
                    <Alert
                        severity="warning"
                        sx={{
                            mb: 3,
                            width: '100%'
                        }}
                    >
                        <AlertTitle>Work in Progress</AlertTitle>
                        Our website is currently under construction. We're working hard to bring you an amazing experience!
                    </Alert>
                </Paper>
            </Container>
        </Box>
    );
};

export default UnderConstruction;