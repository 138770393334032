import React, { useState } from 'react';
import {
    Checkbox,
    FormGroup,
    FormControlLabel,
    Box,
    Typography,
    useTheme
} from '@mui/material';
import { Color } from '../../../Theme/Color';

interface CertificationCheckboxProps {
    onCertificationChange?: (certification: 'certified' | 'non-certified' | null) => void;
}

const CertificationCheckbox: React.FC<CertificationCheckboxProps> = ({
    onCertificationChange
}) => {
    const [selectedCertification, setSelectedCertification] = useState<'certified' | 'non-certified'>('certified');
    const theme = useTheme();

    // Unified dark blue colors
    const customColors = {
        main: Color.DARK_BLUE,      // Dark blue
        dark: Color.DARK_BLUE,      // Darker blue for hover/active states
        light: Color.BLUE,     // Lighter blue for hover effects
        background: theme.palette.mode === 'light'
            ? 'rgba(25, 118, 210, 0.04)'
            : 'rgba(25, 118, 210, 0.08)'
    };

    const handleCheckboxChange = (type: 'certified' | 'non-certified') => {
        if (selectedCertification !== type) {
            setSelectedCertification(type);
            if (onCertificationChange) {
                onCertificationChange(type);
            }
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mt: 1,
            backgroundColor: customColors.background,
            padding: 1,
            borderRadius: 2,
            border: `1px solid ${customColors.main}22`
        }}>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedCertification === 'certified'}
                            onChange={() => handleCheckboxChange('certified')}
                            sx={{
                                color: customColors.main,
                                '&.Mui-checked': {
                                    color: customColors.main,
                                },
                                '&:hover': {
                                    backgroundColor: `${customColors.light}14`,
                                },
                            }}
                        />
                    }
                    label={
                        <Typography
                            variant="body2"
                            sx={{
                                color: selectedCertification === 'certified'
                                    ? customColors.main
                                    : theme.palette.text.secondary,
                                fontWeight: selectedCertification === 'certified' ? 500 : 400
                            }}
                        >
                            Certified
                        </Typography>
                    }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedCertification === 'non-certified'}
                            onChange={() => handleCheckboxChange('non-certified')}
                            sx={{
                                color: customColors.main,
                                '&.Mui-checked': {
                                    color: customColors.main,
                                },
                                '&:hover': {
                                    backgroundColor: `${customColors.light}14`,
                                },
                            }}
                        />
                    }
                    label={
                        <Typography
                            variant="body2"
                            sx={{
                                color: selectedCertification === 'non-certified'
                                    ? customColors.main
                                    : theme.palette.text.secondary,
                                fontWeight: selectedCertification === 'non-certified' ? 500 : 400
                            }}
                        >
                            Non-Certified
                        </Typography>
                    }
                />
            </FormGroup>
        </Box>
    );
};

export default CertificationCheckbox;
