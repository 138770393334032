import React, { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    ThemeProvider,
    createTheme,
    CssBaseline,
    TextField,
    Button,
    Collapse
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import CustomSnackBar, { snackbarType } from '../../../Component/CustomSnackBar';
import { useNavigate } from 'react-router';
import axios from 'axios';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        background: {
            default: 'transparent',
        },
    },
});

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [email, setEmail] = useState('');
    const [isResponseReceived, setIsResponseReceived] = useState(false);
    const [onSuccess, setOnSuccess] = useState<snackbarType>({ success: false, message: '' });

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    // const handleSubmit = async () => {
    //     try {
    //         // Simulating API call
    //         const response = await axios.post("/auth/verify", {
    //             email: formValues.email,
    //             phone_number: formValues.phone_number,
    //         });

    //         if (response.status === 200) {
    //             navigate("/verify", {
    //                 state: {
    //                     email: response.data.data.email,
    //                     data: formValues,
    //                     correctotp: response.data.data.otp,
    //                 },
    //             });
    //             setLoggedIn(false);
    //         }
    //     } catch (error) {
    //         if (error.status === 400) {
    //             setAlertOpen(true);

    //             setErrorMsg(error.response.data.message);

    //             setLoggedIn(false);
    //         }
    //         console.error(error);
    //     }
    // };

    return (
        <ThemeProvider theme={theme}>
            <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    backgroundImage: 'url("/login2.avif")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <Paper
                    elevation={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 4,
                        width: '100%',
                        maxWidth: 400,
                        backgroundColor: 'rgba(255, 255, 255, 0.75)',
                        backdropFilter: 'blur(10px)',
                        borderRadius: '16px',
                    }}
                >
                    <LockResetIcon
                        sx={{ fontSize: 64, color: '#1976d2', marginBottom: '16px' }}
                    />
                    <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">
                        Forgot Password
                    </Typography>
                    <Typography variant="body1" align="center" gutterBottom>
                        Enter your registered email address to receive password reset instructions.
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Email Address"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: 'white',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#1976d2',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#1976d2',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#1976d2',
                                },
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        // onClick={handleSubmit}
                        disabled={!email}
                        sx={{
                            mt: 2,
                            py: 1.5,
                            fontSize: '1.1rem',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                backgroundColor: '#1565c0',
                            },
                        }}
                    >
                        Send OTP
                    </Button>
                    <Collapse in={isResponseReceived} sx={{ width: '100%', mt: 2 }}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                borderRadius: '8px',
                            }}
                        >
                            <Typography variant="body1" align="center">
                                Password reset instructions have been sent to your email. Please check your inbox and follow the provided steps.
                            </Typography>
                        </Paper>
                    </Collapse>
                </Paper>
            </Box>
        </ThemeProvider>
    );
};

export default ForgotPassword;