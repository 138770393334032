import React, { useState, useEffect, ChangeEvent } from 'react';
import { Box, TextField, Button, Typography, Grid, Input } from '@mui/material';
import { useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';

import { PurchaseContext } from '../Context/purchaseProviderContext';
import CustomSnackBar, { snackbarType } from '../../../Component/CustomSnackBar';
import { setUserData } from '../../../store/slice/userSlice';
// import { API_BASE_URL } from '../../../backend';

const PurchaseDetails: React.FC = () => {

    const dispatch = useDispatch();
    const [purchaseBack, setPurchaseBack] = useState<number>(0);

    const { filterFormState, filterFormDispatch } = useContext(PurchaseContext);
    const [filterFormData, setFilterFormData] = useState<any>(filterFormState);
    const [onSuccess, setOnSuccess] = useState<snackbarType>({ success: false, message: '' });



    useEffect(() => {
        setFilterFormData(filterFormState);
    }, [filterFormState]);


    const handleSubmit = async () => {
        // filterFormDispatch({ type: 'SET_FILTER_FORM', payload: filterFormData });

        // Send data to backend using axios



        try {

            const response = await axios.post("/stock/create-stock", filterFormData);

            if (response.status === 201) {
                setOnSuccess({ message: "Data submitted successfully!", success: true, variant: "success" })
                dispatch(setUserData({ last_stock_id: filterFormData.last_stock_id, invoice_no: filterFormData.invoice_number }));
                // user.last_stock_id = filterFormData.last_stock_id


                // alert('Data submitted successfully!');
            } else {
                // alert('Failed to submit data. Please try again.');
                setOnSuccess({ message: "Failed to submit data. Please try again.", success: true, variant: "error" })
            }
        } catch (error) {
            console.error('Error:', error);
            setOnSuccess({ message: "Failed to submit data. Please try again.", success: true, variant: "error" })
        }
    }

    const handleInputChange = (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setFilterFormData((filterFormData: any) => ({
            ...filterFormData,
            [fieldName]: value
        }));
    }


    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/csv') {
            setSelectedFile(file);
        } else {
            alert('Please select a valid CSV file.');
            event.target.value = ''; // Reset the file input
        }
    };
    const handlePurchaseBack = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setPurchaseBack(value === "" ? 0 : Number(value));
    };
    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Please select a CSV file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);


        try {
            const response = await axios.post("/rap/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                alert('File uploaded successfully!');
                setSelectedFile(null);
            } else {
                alert('Failed to upload file. Please try again.');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Axios error:', error.response?.data || error.message);
            } else {
                console.error('Error:', error);
            }
            alert('An error occurred while uploading the file.');
        }
    };

    // const handleUpload = async () => {
    //     if (!selectedFile) {
    //         alert('Please select a CSV file to upload.');
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('file', selectedFile);
    //     console.log(formData)
    //     try {


    //         const response = await axios.post('/api/upload', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         });

    //         if (response.status === 200) {
    //             alert('File uploaded successfully!');
    //             setSelectedFile(null); // Reset selectedFile after successful upload
    //         } else {
    //             alert('Failed to upload file. Please try again.');
    //         }
    //     } catch (error) {
    //         if (axios.isAxiosError(error)) {
    //             console.error('Axios error:', error.response?.data || error.message);
    //         } else {
    //             console.error('Error:', error);
    //         }
    //         alert('An error occurred while uploading the file.');
    //     }

    // };

    return (
        <Box sx={{ padding: 2, backgroundColor: '#d9e3ef', borderRadius: 2 }}>
            <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />
            <Typography variant="h6" gutterBottom>
                Purchase Details
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        sx={{ backgroundColor: "white", borderRadius: 1 }}
                        label="Purchase Back%"
                        fullWidth
                        // value={purchaseBack}

                        onChange={handlePurchaseBack}
                        type="number"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField sx={{ backgroundColor: "white", borderRadius: 1 }} value={filterFormData.less1
                        ? (
                            (filterFormData.price * (purchaseBack > 0 ? (1 - purchaseBack * 0.01) : 1))
                            - (parseFloat(filterFormData.less1) * 0.01)
                        ) || ""
                        : (
                            filterFormData.price * (purchaseBack > 0 ? (1 - purchaseBack * 0.01) : 1)
                        ) || ""}
                        label="Purchase Rate/ᶜᵃʳᵃᵗ" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField sx={{ backgroundColor: "white", borderRadius: 1 }} label="Cash Price" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField

                        value={filterFormState.price || ""}
                        sx={{ backgroundColor: "white", borderRadius: 1 }}

                        label="Rap"
                        fullWidth
                        InputLabelProps={{ shrink: Boolean(filterFormState.price) }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={filterFormData.less1
                            ? (
                                (filterFormData.price * (filterFormData.weight !== undefined && filterFormData.weight !== null ? filterFormData.weight : filterFormData.size_end) * (purchaseBack > 0 ? (1 - purchaseBack * 0.01) : 1))
                                - (parseFloat(filterFormData.less1) * 0.01)
                            ) || ""
                            : (
                                filterFormData.price * (filterFormData.weight !== undefined && filterFormData.weight !== null ? filterFormData.weight : filterFormData.size_end) * (purchaseBack > 0 ? (1 - purchaseBack * 0.01) : 1)
                            ) || ""}
                        sx={{ backgroundColor: "white", borderRadius: 1 }}
                        label="Rap $"
                        fullWidth
                        InputLabelProps={{ shrink: Boolean(filterFormData.price) }}
                    />



                </Grid>
                <Grid item xs={6}>
                    <TextField sx={{ backgroundColor: "white", borderRadius: 1 }} label="Vatav" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField sx={{ backgroundColor: "white", borderRadius: 1 }} label="Transaction Cost" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField sx={{ backgroundColor: "white", borderRadius: 1 }} label="Total Vat" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField sx={{ backgroundColor: "white", borderRadius: 1 }} label="Add. Exp" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={filterFormData.less1
                            ? (() => {
                                const total = Number(((((filterFormData.price * (filterFormData.weight !== undefined && filterFormData.weight !== null ? filterFormData.weight : filterFormData.size_end)) * (purchaseBack > 0 ? (1 - purchaseBack * 0.01) : 1)) - (parseFloat(filterFormData.less1) * 0.01)) * filterFormData.currency_rate));
                                return !isNaN(total) && total !== 0 ? total.toFixed(2) : "";
                            })()
                            : (() => {
                                const total = Number((filterFormData.price * (filterFormData.weight !== undefined && filterFormData.weight !== null ? filterFormData.weight : filterFormData.size_end) * filterFormData.currency_rate * (purchaseBack > 0 ? (1 - purchaseBack * 0.01) : 1)));
                                return !isNaN(total) && total !== 0 ? total.toFixed(2) : "";
                            })()}
                        sx={{ backgroundColor: "white", borderRadius: 1 }}
                        label="Total Cost"
                        fullWidth
                        InputLabelProps={{ shrink: Boolean(filterFormData.price) }}
                    />


                </Grid>


                <Grid item xs={6}>
                    <TextField sx={{ backgroundColor: "white", borderRadius: 1 }} label="Total Brokerage" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Input
                        type="file"
                        inputProps={{ accept: '.csv' }}
                        onChange={handleFileChange}
                        sx={{ display: 'none' }}
                        id="csv-upload-input"
                    />
                    <label htmlFor="csv-upload-input">
                        <Button
                            variant="contained"
                            color="secondary"
                            component="span"
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            Select CSV File
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={handleUpload}
                        disabled={!selectedFile}
                    >
                        Upload CSV
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PurchaseDetails;
