import * as React from 'react';
import { Box, Typography, Container, DialogContent, DialogContentText } from '@mui/material';

export default function TermsAndConditions() {
    return (
        <Container maxWidth="lg" sx={{ mt: 7, mb: 4 }}>
            <Typography variant="h4" gutterBottom align="center">
                Terms and Conditions
            </Typography>
            <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                    By accessing or using the inventory management system of Superb Diamonds, you agree to be bound by these terms and conditions. If you do not agree to these terms, you may not use the Product.
                </DialogContentText>

                {/* Data Responsibility */}
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    1. Data Responsibility
                </Typography>
                <DialogContentText sx={{ mb: 2 }}>
                    You are solely responsible for the accuracy, completeness, and legality of all data entered into the Product. We do not verify the data and are not liable for any inaccuracies or errors. You agree to regularly back up your data to prevent any loss.
                </DialogContentText>
                <DialogContentText sx={{ mb: 2 }}>
                    By using the Product, you acknowledge that the data entered is your sole responsibility, and we shall not be held liable for any loss or corruption of data. You agree that in the event of system failure or data loss, your sole remedy is to discontinue the use of the Product.
                </DialogContentText>

                {/* Security and Data Breaches */}
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    2. Security and Data Breaches
                </Typography>
                <DialogContentText sx={{ mb: 2 }}>
                    We implement standard security measures to protect your data; however, absolute security cannot be guaranteed. In the event of a data breach, loss, or unauthorized access caused by unforeseen circumstances, third-party actions, or other factors, we will not be liable for any damages or losses.
                </DialogContentText>
                <DialogContentText sx={{ mb: 2 }}>
                    As per the Information Technology Act, 2000, and the IT (Reasonable Security Practices and Procedures) Rules, 2011, we are obligated to notify users in the event of any breach or unauthorized access to personal data. However, by using the Product, you acknowledge the risks of data breaches and agree to assume responsibility for any consequences arising from such events.
                </DialogContentText>

                {/* User Conduct */}
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    3. User Conduct
                </Typography>
                <DialogContentText sx={{ mb: 2 }}>
                    You agree not to use the Product for any unlawful or prohibited purposes. This includes, but is not limited to, using the Product to infringe on any intellectual property rights, distribute harmful content, or breach any applicable laws in India.
                </DialogContentText>
                <DialogContentText sx={{ mb: 2 }}>
                    Any misuse of the Product that violates Indian laws, including the Indian Penal Code, the Information Technology Act, 2000, or any other relevant legislation, may result in immediate termination of your access to the Product.
                </DialogContentText>

                {/* Intellectual Property */}
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    4. Intellectual Property
                </Typography>
                <DialogContentText sx={{ mb: 2 }}>
                    All intellectual property rights in the Product, including its design, content, and software, are owned by or licensed to Superb Diamonds. You agree not to reproduce, distribute, or create derivative works based on any aspect of the Product without our prior written consent.
                </DialogContentText>

                {/* Limitation of Liability */}
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    5. Limitation of Liability
                </Typography>
                <DialogContentText sx={{ mb: 2 }}>
                    To the extent permitted by applicable Indian law, we will not be liable for any indirect, incidental, or consequential damages arising out of your use of the Product, including loss of profits, data, or business opportunities.
                </DialogContentText>
                <DialogContentText sx={{ mb: 2 }}>
                    In no event will our total liability to you for any claims arising out of your use of the Product exceed the amount paid by you, if any, for accessing or using the Product.
                </DialogContentText>

                {/* Governing Law */}
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    6. Governing Law and Dispute Resolution
                </Typography>
                <DialogContentText sx={{ mb: 2 }}>
                    These terms and conditions are governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these terms will be subject to the exclusive jurisdiction of the courts in Surat, India.
                </DialogContentText>
                <DialogContentText sx={{ mb: 2 }}>
                    Any disputes arising from the use of the Product shall be resolved through mediation or arbitration in accordance with the Arbitration and Conciliation Act, 1996, as amended, before resorting to court proceedings.
                </DialogContentText>

                {/* Changes to Terms */}
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    7. Changes to the Terms
                </Typography>
                <DialogContentText sx={{ mb: 2 }}>
                    We reserve the right to modify these terms and conditions at any time. We will notify you of any changes via email or through notices on the Product interface. Your continued use of the Product after the changes will be considered your acceptance of the revised terms.
                </DialogContentText>

                {/* Contact Information */}
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    8. Contact Information
                </Typography>
                <DialogContentText sx={{ mb: 2 }}>
                    If you have any questions or concerns about these terms, please contact us at superb.diamonds@gmail.com
                </DialogContentText>
            </DialogContent>
        </Container>
    );
}
