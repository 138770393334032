import React from "react";
import { useSelector } from "react-redux";
import {
    Container,
    Typography,
    Box,
    Grid,
    Card,
    CardContent,
    Avatar,
    Divider,
    useTheme,
    styled,
    Chip,
} from "@mui/material";
import {
    Person as PersonIcon,
    Phone as PhoneIcon,
    Email as EmailIcon,
    Business as BusinessIcon,
    LocationOn as LocationOnIcon,
    Work as WorkIcon,
    Group as GroupIcon,
} from "@mui/icons-material";
import { RootState } from "../../store/store";

const StyledInfoItem = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(3),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    minWidth: "24px",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    position: "relative",
    "&:after": {
        content: '""',
        position: "absolute",
        bottom: "-8px",
        left: 0,
        width: "40px",
        height: "3px",
        backgroundColor: theme.palette.primary.main,
    },
}));

const Profile: React.FC = () => {
    const theme = useTheme();
    const user = useSelector((state: RootState) => state.user.data);

    if (!user) {
        return <Typography>Loading...</Typography>;
    }

    const hasPartners = user.partners && user.partners.length > 0;

    return (
        <Container maxWidth="lg" sx={{ py: 6, mt: 5 }}>
            <Grid container spacing={4}>
                <Grid item xs={hasPartners ? 12 : 14} md={4}>
                    <Card elevation={3} sx={{ height: "100%", position: "relative", overflow: "visible" }}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "-40px",
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "80px",
                                height: "80px",
                                borderRadius: "50%",
                                backgroundColor: theme.palette.background.paper,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: theme.shadows[3],
                            }}
                        >
                            <Avatar
                                sx={{ width: 72, height: 72, bgcolor: "primary.main" }}
                            >
                                <PersonIcon sx={{ fontSize: 40 }} />
                            </Avatar>
                        </Box>
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                                pt: 7,
                            }}
                        >
                            <Typography variant="h5" gutterBottom>{`${user.first_name} ${user.last_name}`}</Typography>
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                @{user.user_name}
                            </Typography>
                            <Box sx={{ mt: 2, mb: 3 }}>
                                {user.occupation && user.occupation.map((occ, index) => (
                                    <Chip
                                        key={index}
                                        label={occ}
                                        color="primary"
                                        size="small"
                                        sx={{ m: 0.5 }}
                                    />
                                ))}
                            </Box>
                            <Typography variant="body2" color="textSecondary">
                                {user.company_name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={hasPartners ? 8 : 11}>
                    <Card elevation={3}>
                        <CardContent>
                            <SectionTitle variant="h6">Personal Information</SectionTitle>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <StyledInfoItem>
                                        <IconWrapper>
                                            <PhoneIcon />
                                        </IconWrapper>
                                        <Box>
                                            <Typography variant="body2" color="textSecondary">
                                                Phone Number
                                            </Typography>
                                            <Typography variant="body1">
                                                {user.phone_number}
                                            </Typography>
                                        </Box>
                                    </StyledInfoItem>
                                    <StyledInfoItem>
                                        <IconWrapper>
                                            <EmailIcon />
                                        </IconWrapper>
                                        <Box>
                                            <Typography variant="body2" color="textSecondary">
                                                Email
                                            </Typography>
                                            <Typography variant="body1">{user.email}</Typography>
                                        </Box>
                                    </StyledInfoItem>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <StyledInfoItem>
                                        <IconWrapper>
                                            <LocationOnIcon />
                                        </IconWrapper>
                                        <Box>
                                            <Typography variant="body2" color="textSecondary">
                                                Country
                                            </Typography>
                                            <Typography variant="body1">{user.country}</Typography>
                                        </Box>
                                    </StyledInfoItem>
                                    <StyledInfoItem>
                                        <IconWrapper>
                                            <BusinessIcon />
                                        </IconWrapper>
                                        <Box>
                                            <Typography variant="body2" color="textSecondary">
                                                Company Address
                                            </Typography>
                                            <Typography variant="body1">
                                                {user.company_address}
                                            </Typography>
                                        </Box>
                                    </StyledInfoItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {hasPartners && (
                <Box mt={6}>
                    <SectionTitle variant="h6">Partners</SectionTitle>
                    <Grid container spacing={3}>
                        {user.partners && user.partners.length > 0 && user.partners.map((partner, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card elevation={3}>
                                    <CardContent>
                                        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                            <Avatar sx={{ bgcolor: "secondary.main", mr: 2 }}>
                                                <GroupIcon />
                                            </Avatar>
                                            <Typography variant="h6">{`${partner.first_name} ${partner.last_name}`}</Typography>
                                        </Box>
                                        <Divider sx={{ mb: 2 }} />
                                        <StyledInfoItem>
                                            <IconWrapper>
                                                <EmailIcon />
                                            </IconWrapper>
                                            <Box>
                                                <Typography variant="body2" color="textSecondary">Email</Typography>
                                                <Typography variant="body1">{partner.email}</Typography>
                                            </Box>
                                        </StyledInfoItem>
                                        <StyledInfoItem>
                                            <IconWrapper>
                                                <PhoneIcon />
                                            </IconWrapper>
                                            <Box>
                                                <Typography variant="body2" color="textSecondary">Phone</Typography>
                                                <Typography variant="body1">{partner.phone_number}</Typography>
                                            </Box>
                                        </StyledInfoItem>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Container>
    );
};

export default Profile;