import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormControl, Grid, TextField, Box, Typography, FormLabel, IconButton, InputLabel, Select, SelectChangeEvent, MenuItem, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { diamondTradingCountries, LooseSize, shapes } from '../../../Constants/FilterFormItems';
import { Color } from '../../../Theme/Color';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import CustomSnackBar, { snackbarType } from '../../../Component/CustomSnackBar';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../../store/slice/userSlice';

export default function StockNumber() {
    const user = useSelector((state: RootState) => state.user.data);
    const InvoicePrefix = user?.invoice_no?.replace(/\d+$/, '') || '';
    const InvoiceNumber = user?.invoice_no?.match(/\d+$/)?.[0] || '0';
    const incrementedNumber = (parseInt(InvoiceNumber, 10) + 1).toString().padStart(InvoiceNumber.length, '0');
    const dispatch = useDispatch();

    const [NewInvoice, setNewInvoice] = useState(InvoicePrefix + incrementedNumber);

    console.log(NewInvoice);

    const countryOptions = [
        { value: 'India', flag: 'https://flagcdn.com/w20/in.png' },
        { value: 'United States', flag: 'https://flagcdn.com/w20/us.png' },
        { value: 'Euro', flag: 'https://flagcdn.com/w20/eu.png' },
        { value: 'Japan', flag: 'https://flagcdn.com/w20/jp.png' },
        { value: 'Thailand', flag: 'https://flagcdn.com/w20/th.png' },
        { value: 'Hong Kong', flag: 'https://flagcdn.com/w20/hk.png' },
        { value: 'Dubai', flag: 'https://flagcdn.com/w20/ae.png' },
        { value: 'Israel', flag: 'https://flagcdn.com/w20/il.png' },
        { value: 'Vietnam', flag: 'https://flagcdn.com/w20/vn.png' },
        { value: 'Cambodia', flag: 'https://flagcdn.com/w20/kh.png' },
        { value: 'China', flag: 'https://flagcdn.com/w20/cn.png' },

    ];

    const [selectedCountry, setSelectedCountry] = useState(countryOptions[0].value);

    const [onSuccess, setOnSuccess] = useState<snackbarType>({ success: false, message: '' });
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const [filterFormData, setFilterFormData] = useState<any>({
        invoice_no: NewInvoice,
        total_price: 0,
        deal_date: new Date(),
        location: 'India'

    });
    const handleCountryChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        setSelectedCountry(selectedValue);
        setFilterFormData((filterFormData: any) => ({
            ...filterFormData,
            location: selectedValue
        }));

    };
    console.log("THIS IS NON CERTI", filterFormData)
    const handleSieveChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setFilterFormData((prevState: any) => ({
            ...prevState,
            sieve: value
        }));
    };
    const handleInputChange = (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setFilterFormData((prevFormData: any) => {
            const updatedFormData = { ...prevFormData, [fieldName]: inputValue };


            if (['weight', 'purchase_carat', 'less', 'converted_rate'].includes(fieldName)) {
                const weight = parseFloat(updatedFormData.weight || '0') || 0;
                const purchaseCarat = parseFloat(updatedFormData.purchase_carat || '0') || 0;
                const less = parseFloat(updatedFormData.less || '0') || 0;
                const convertedRate = parseFloat(updatedFormData.converted_rate || '1') || 1;

                let totalPrice = weight * purchaseCarat;
                if (less > 0) {
                    totalPrice -= totalPrice * (less / 100);
                }
                totalPrice *= convertedRate;

                return { ...updatedFormData, total_price: Math.trunc(totalPrice) };
            }

            return updatedFormData;
        });
    };

    const handleShapeClick = (shape: string) => {
        setFilterFormData((prevData: any) => ({
            ...prevData,
            shape
        }));
    };

    const [showAllShapes, setShowAllShapes] = useState<boolean>(false);
    const visibleShapes = showAllShapes ? shapes : shapes.slice(0, 12);
    const handleLooseStock = async () => {
        try {
            const response = await axios.post('/loosestock/create-stock', filterFormData);
            if (response.status == 201) {
                setOnSuccess({ message: `Item added successfully`, success: true, variant: "success" });
                const InvoicePrefix = NewInvoice?.replace(/\d+$/, '') || '';
                const InvoiceNumber = NewInvoice?.match(/\d+$/)?.[0] || '0';
                const incrementedNumber = (parseInt(InvoiceNumber, 10) + 1).toString().padStart(InvoiceNumber.length, '0');
                setNewInvoice(InvoicePrefix + incrementedNumber)
                dispatch(setUserData({ invoice_no: NewInvoice }));

            }
        } catch (error: any) {
            setOnSuccess({ message: error.message, success: true, variant: "error" });
        }
    };

    return (
        <Box sx={{ flexGrow: 1, display: "flex", width: '100%', mt: 8 }}>
            <CustomSnackBar onSuccess={onSuccess} setOnSuccess={setOnSuccess} />
            <Grid container spacing={2} sx={{ width: '100%' }}>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        <Grid container spacing={2} justifyContent="center">
                            {/* Invoice Number & Deal Date */}
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 2, borderRadius: 2 }}>
                                    <Grid container spacing={2} mb={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                size="small"
                                                sx={{ backgroundColor: "white", borderRadius: 2 }}
                                                fullWidth
                                                value={NewInvoice}
                                                label="Invoice Number"
                                                type="text"
                                                onChange={handleInputChange('invoice_no')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                                label="Deal Date"
                                                fullWidth
                                                defaultValue={getCurrentDate()}
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={handleInputChange('deal_date')}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>

                            {/* Shape Selection */}
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 2, borderRadius: 2 }}>
                                    <FormLabel sx={{ mb: 1 }}>Shape</FormLabel>
                                    <Grid container spacing={1}>
                                        {visibleShapes.map((shape) => (
                                            <Grid item xs={6} sm={3} md={2} lg={1} key={shape}>
                                                <div
                                                    onClick={() => handleShapeClick(shape)}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                        border: (filterFormData.shape?.includes(shape) ?? false) ? `2px solid ${Color.DARK_BLUE}` : '2px solid transparent',
                                                        borderRadius: 3,
                                                        padding: '5px',
                                                    }}
                                                >
                                                    <img src={`../DiamondTypes/${shape}.png`} alt={shape} style={{ maxWidth: '100%', height: '60px', borderRadius: 5 }} />
                                                    <Typography variant="caption">{shape}</Typography>
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Box sx={{ m: 'auto' }} onClick={() => setShowAllShapes(!showAllShapes)}>
                                        <IconButton>
                                            {showAllShapes ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Box>
                                </FormControl>
                            </Grid>

                            {/* Weight, Number of Stones, Purchase/carat, and Less */}
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 3, borderRadius: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                value={filterFormData.weight}
                                                onChange={handleInputChange('weight')}
                                                label="Weight (carats)"
                                                sx={{ backgroundColor: "white", border: 'none', borderRadius: 1 }}
                                            />
                                        </Grid>
                                        {/* Number of Stones */}

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth

                                                value={filterFormData.broker_name}
                                                onChange={handleInputChange('broker_name')}
                                                label="Broker name"
                                                sx={{ backgroundColor: "white", border: 'none', borderRadius: 1 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                fullWidth

                                                value={filterFormData.party_name}
                                                onChange={handleInputChange('party_name')}
                                                label="Party name"
                                                sx={{ backgroundColor: "white", border: 'none', borderRadius: 1 }}
                                            />
                                        </Grid>

                                        {/* Purchase/carat */}
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                value={filterFormData.purchase_carat || ''}
                                                onChange={handleInputChange('purchase_carat')}
                                                label="Purchase/carat"
                                                sx={{ backgroundColor: "white", border: 'none', borderRadius: 1 }}
                                            />
                                        </Grid>

                                        {/* Less */}
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                value={filterFormData.less || ''}
                                                onChange={handleInputChange('less')}
                                                label="Less(%)"
                                                sx={{ backgroundColor: "white", border: 'none', borderRadius: 1 }}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid><Grid item xs={12}>
                                <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 2, borderRadius: 2 }}>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth style={{ borderRadius: 4, backgroundColor: "white" }}>
                                                <InputLabel>Location</InputLabel>
                                                <Select
                                                    value={selectedCountry}
                                                    onChange={handleCountryChange}
                                                    label="Location"
                                                    renderValue={(value) => (
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <img
                                                                src={countryOptions.find(option => option.value === value)?.flag}
                                                                alt={countryOptions.find(option => option.value === value)?.value}
                                                                style={{ marginRight: '8px', width: '24px', height: '16px' }}
                                                            />
                                                            {countryOptions.find(option => option.value === value)?.value}
                                                        </Box>
                                                    )}
                                                >
                                                    {countryOptions.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={option.flag}
                                                                    alt={option.value}
                                                                    style={{ marginRight: '8px', width: '24px', height: '16px' }}
                                                                />
                                                                {option.value}
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                type='number'
                                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                                value={filterFormData.converted_rate || ''}
                                                label="Converted rate"
                                                fullWidth
                                                onChange={handleInputChange('converted_rate')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField

                                                type='number'
                                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                                value={filterFormData.days}
                                                label="Days"
                                                fullWidth
                                                onChange={handleInputChange('days')}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        value={filterFormData.sieve || ''}
                                                        sx={{ backgroundColor: "white", border: 'none' }}
                                                        onChange={handleSieveChange}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="" disabled>
                                                            Select sieve size
                                                        </MenuItem>
                                                        {LooseSize.map((size) => (
                                                            <MenuItem key={size} value={size}>
                                                                {size}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ backgroundColor: '#d9e3ef', padding: 3, borderRadius: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                value={filterFormData.number_of_stones}
                                                onChange={handleInputChange('number_of_stones')}
                                                label="Number of Stones"
                                                sx={{ backgroundColor: "white", border: 'none', borderRadius: 1 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                value={filterFormData.total_price}
                                                onChange={handleInputChange('total_price')}
                                                label="Total Price"
                                                sx={{ backgroundColor: "white", border: 'none', borderRadius: 1 }}


                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                style={{ borderRadius: 4, backgroundColor: "white" }}
                                                label="Comments"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                value={filterFormData.comment}
                                                onChange={handleInputChange('comment')}
                                            /></Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 3 }}
                                onClick={handleLooseStock}
                            >
                                <ShoppingCartIcon sx={{ marginRight: 1 }} />
                                Purchase Stock
                            </Button>
                        </Grid>
                    </Box>
                </Grid >
            </Grid >
        </Box >
    );
}


