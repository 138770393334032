import React from 'react';
import { Box, Typography, Grid, TextField, Button, Container, Link, Divider, Card, CardContent, IconButton } from '@mui/material';
// import { Email, Phone } from 'lucide-react';
import { Email, Phone } from '@mui/icons-material';

interface Partner {
  name: string;
  email: string;
  phone: string;
}

const partners: Partner[] = [
  {
    name: 'Mehul Mehta',
    email: 'mehulkm1973@yahoo.com',
    phone: '+66 896737535',
  },
  {
    name: 'Saurabh Mehta',
    email: 'saurabhkm1@yahoo.com',
    phone: '+91 9825351170',
  },
  {
    name: 'Ankit Mehta',
    email: 'ankit.mehta22@yahoo.com',
    phone: '+91 9825188599',
  },
];

const ContactUs: React.FC = () => {
  return (
    <Box
      sx={{
        mt: 4,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Container maxWidth="lg">
        <Box my={4}>
          <Typography variant="h3" component="h1" gutterBottom align="center" color="primary" fontWeight="bold">
            Contact Us
          </Typography>
          <Typography variant="h6" gutterBottom align="center" color="text.secondary">
            Not sure what you need? You can contact us using the details below or suggest an idea.
          </Typography>

          <Grid container spacing={4} my={4}>
            {partners.map((partner, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card elevation={3} sx={{ height: '100%', transition: '0.3s', '&:hover': { transform: 'translateY(-5px)' } }}>
                  <CardContent>
                    <Typography variant="h5" gutterBottom color="primary" fontWeight="bold">
                      {partner.name}
                    </Typography>
                    <Box display="flex" alignItems="center" mb={1}>
                      <IconButton size="small" color="primary" href={`mailto:${partner.email}`}>
                        <Email />
                      </IconButton>
                      <Link href={`mailto:${partner.email}`} color="inherit" sx={{ ml: 1 }}>
                        <Typography variant="body2">{partner.email}</Typography>
                      </Link>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <IconButton size="small" color="primary" href={`tel:${partner.phone}`}>
                        <Phone />
                      </IconButton>
                      <Link href={`tel:${partner.phone}`} color="inherit" sx={{ ml: 1 }}>
                        <Typography variant="body2">{partner.phone}</Typography>
                      </Link>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Divider sx={{ my: 4 }} />

          <Box mb={4}>
            <Typography variant="h4" gutterBottom color="primary" fontWeight="bold">
              Developer Contact
            </Typography>
            <Typography variant="body1" mb={2}>
              For website-related queries or to connect with the developer, please contact:
            </Typography>
            <Card elevation={2} sx={{ maxWidth: 'md', mx: 'auto' }}>
              <CardContent>
                <Typography variant="h6" fontWeight="bold">Krish Mehta</Typography>
                <Box display="flex" alignItems="center" mb={1}>
                  <IconButton size="small" color="primary" href="mailto:krish.mehta.3822@gmail.com">
                    <Email />
                  </IconButton>
                  <Link href="mailto:krish.mehta.3822@gmail.com" color="inherit" sx={{ ml: 1 }}>
                    <Typography variant="body2">krish.mehta.3822@gmail.com</Typography>
                  </Link>
                </Box>
                <Box display="flex" alignItems="center">
                  <IconButton size="small" color="primary" href="tel:+918401265994">
                    <Phone />
                  </IconButton>
                  <Link href="tel:+918401265994" color="inherit" sx={{ ml: 1 }}>
                    <Typography variant="body2">+91 8401265994</Typography>
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Typography variant="h4" gutterBottom color="primary" fontWeight="bold">
            Ask a Question or Suggest an Idea
          </Typography>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Your Name"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Your Email"
                  variant="outlined"
                  type="email"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Your Question or Suggestion"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit" size="large">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default ContactUs;